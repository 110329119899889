import React, {useState, useContext} from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
// import core components
import { AddressDropDownMenu, InputGroup } from 'components';
import Time from './Time';
import { MultiStepContext, UserContext } from 'contexts'
import GoogleAutoComplete from 'components/auto-complete/GoogleAutoComplete';
import { multistepHelper, domHelper } from 'helpers';
import { useEffect } from 'react';
import { addressService  } from 'services';
// import static assets
import LoadingGif from '/static/gifs/loading.gif';

const AddressForm = styled.form`
    margin: 0px;
    h1 {
      font-weight: bold;
      font-size: 18px;
      line-height: 38px;
    }
    > div {
        display: flex;
        flex-direction: column;
        border: none;
    }
    input[type='submit'], input[type='button'] {
        outline: none;
        cursor: pointer;
        font-weight: ${props => props.theme.fontWeight.button};
    }
    .addressFields {
        display: flex;
        flex-wrap: wrap;
        .address-field {
            width: calc(50% - 10px);
            &:nth-of-type(even) {
                margin-left: 20px;
            }
        }
    }
    .row {
        justify-content: space-between;
        .addressInput {
            width: 40%;
        }
        .addressCity {
            width: 55%;
        }
        .addressState {
            width: 25%;
        }
        .addressPostcode {
            width: 25%;
        }
        .addressCountry {
            width: 45%;
        }
    }
`;

const FieldSet = styled.div`
    margin-top: 10px;
    font-size: 14px;
    > div.fieldset {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    input[type='text'], input[type='search'] {
        height: 45px;
        border: 1px solid ${props => props.theme.colors.stroke};
        &.required {
            border: 1px solid ${props => props.theme.colors.error};
        }
        box-sizing: border-box;
        border-radius: ${props => props.theme.borderRadius.round};
        padding: 0px 6px 0px 6px;
        outline: none;
        width: 100%;
        margin-bottom: 10px;
    }
    input[type='submit'] {
        background-color: ${props => props.theme.colors.blue};
        width: 100%;
        border-radius: 10px;
        height: 60px;
        border: none;
        color: white;
    }
    h3 {
        opacity: 0.5;
        font-size: 14px;
        font-weight: normal;
    }
    
`;
const Error = styled.div`
    margin-top: 10px;
    > p {
        color:red;
        text-align: center;
    }
`;
const DropDownWrapper = styled.div`
    margin-bottom: 60px;
`;

const Address = props => {
    const { deliveryAddress, setDeliveryAddress, deliveryUser, setDeliveryUser, deliveryOption, warnings } = props;
    const { currentStep } = useContext(MultiStepContext);
    const { user, createNotification } = useContext(UserContext); // Context API to get user state
    const [bLoadingAddresses, setBLoadingAddresses] = useState(true);
    // mocked-up data for address-book
    const [addressBook,setAddressBook] = useState([]);
    const loadAddressBook = async userId => {
        setBLoadingAddresses(true);
        try {
            setAddressBook(await addressService.getDeliveryAddresses(userId) || []);
            setBLoadingAddresses(true);
            // createNotification('success', "Addressbook is loaded");
        } catch (error) {
            setAddressBook([]);
        }
        setBLoadingAddresses(false);
    }
    const selectAddress = addr => {
        setDeliveryAddress({
            address1: addr.addressLine1,
            address2: addr.addressLine2,
            locality: addr.addressCity,
            state: addr.addressStateProvice,
            postcode: addr.addressPostcode,
            country: addr.addressCountry,
        });
        setDeliveryUser(prevState=>{
            return {
                ...prevState,
                fullName: addr.addressName,
                relationship: addr.relationshipName,
                phone: multistepHelper.isCourierOptionSelected(deliveryOption) ? addr.addressPhoneNumber : "",
                company: addr.addressCompany,
            }
        });
    }
    const handleChange = ev => {
        const name = ev.target.name;
        const value = ev.target.value;
        setDeliveryUser(prevState=>{
            return {...prevState, [name]: value}
        })
    }
    useEffect(() => {
        user && loadAddressBook(user.cognitoUserId);
    }, [user])
    
    return (
            <AddressForm>
                <Time {...props}/>
                {user && bLoadingAddresses &&   <span className="warning loading">
                    Loading addresses... <img className="loading-gif small" src={LoadingGif} alt="image loading" />
                </span>}
                {user && !bLoadingAddresses && (addressBook.length > 0 ? <DropDownWrapper>
                    <AddressDropDownMenu
                        menu={ addressBook }
                        handleClick = {selectAddress}
                    />
                </DropDownWrapper> : <span className="warning">
                    You currently have no addresses saved.
                </span>)}
                {!user && <span className="warning">
                    <FontAwesomeIcon icon={faExclamationCircle} />
                    &nbsp;<a onClick={ev => domHelper.triggerClick("login-button")}>Sign In</a>
                    &nbsp;to open your address book
                </span>}
                <FieldSet>
                    <div className="fieldset">
                        <label>Name</label>
                        <InputGroup
                            name={"fullName"}
                            value={deliveryUser.fullName}
                            handleChange={handleChange}
                            required={warnings.length > 0}
                        />
                    </div>
                    {/* <div className="fieldset">
                        <label>Relationship</label>
                        <input 
                            name={"relationship"}
                            value={deliveryUser.relationship}
                            onChange={(ev) => setDeliveryUser({...deliveryUser, relationship: ev.target.value})}
                            type="text"
                        />
                    </div> */}
                    <div className={`fieldset ${multistepHelper.isCourierOptionSelected(deliveryOption) ? "" : "hidden"}`}>
                        <label>Phone Number</label>
                        <InputGroup
                            name={"phone"}
                            value={deliveryUser.phone}
                            handleChange={handleChange}
                            required={warnings.length > 0}
                        />
                    </div>
                    <div className="fieldset">
                        <label>Company</label>
                        <InputGroup
                            name={"company"}
                            value={deliveryUser.company}
                            handleChange={handleChange}
                            required={false}
                        />
                    </div>
                    <div className="fieldset">
                        <label htmlFor="address1" >Deliver to</label>
                        <GoogleAutoComplete
                            apiKey={process.env.GATSBY_GOOGLE_MAPS_API}
                            placeholder="Start typing a location..."
                            deliveryAddress={deliveryAddress}
                            setDeliveryAddress={setDeliveryAddress}
                            required={warnings.length > 0}
                        />
                    </div>
                </FieldSet>
            </AddressForm>
    )
}

export default Address;