import React, { useState, useContext, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { Tooltip } from 'react-tooltip'
import { useStaticQuery, graphql } from 'gatsby';
import {debounce} from 'lodash';
import PropTypes from 'prop-types';
// import core components
import Menu from '../MenuLegacy';
import Library from '../Library';
import { modalConstants } from '../../../../constants';
import { queryHelper } from 'helpers';
import { MenuBarContext } from 'contexts';
import { useOnClickOutside } from 'hooks';
import { layoutTypeConstants } from '../../../../constants';
// import menubar icons
import LayoutIcon from '/static/icons/menu/layout.svg';
import { useSwipeDetector } from '../../../../hooks';

const Wrapper = styled.div`
 
  display: flex;
  justify-content: center;
  position: relative;
  &.mobileWrapper{
    position:fixed;
    ${({isAnyMenuActive})=> isAnyMenuActive ? `
    bottom:144px;
    ` : `
    bottom:0;
    `}
    
    left: 0;
    z-index:9;
    width: 100vw;
    filter: drop-shadow(0px -5px 4px rgba(0,0,0,0.25));
    border: 1px solid #EBEBEB;
    background: white;
    .dotImage{
      position: absolute;
      top: -15px;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      border: 1px solid #EBEBEB;
      background: white;
      border-radius: 8px;
      padding: 0px 5px;
    }
  }
`;
const ButtonWrapper = styled.div`
  margin-right: 30px;
  &:last-of-type {
    margin-right: 0px;
  }


  ${({ isActive }) => isActive?`
  color: #5095BF;
  `:``}
  ${({ isMobile }) => isMobile?`
    background: white;
    z-index: 1;
    padding: 10px 0px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
  `:``}
  a > svg {
    width: 24px;
    height: 24px;
    margin-bottom: 10px;
    filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.07));
    cursor: pointer;
  }
  > div {
    position: absolute;
    background: ${({ theme }) => theme.colors.white};
    box-shadow: ${({ theme }) => theme.shadow.menu};
    width: 100%;
    max-width: 600px;
    left: 0;
    ${({ isMobile }) => isMobile?`
    top: 44px;
    `:`
    top: 25px;
    `}
    z-index: 1000;
  }
  .tooltip {
    position: relative;
    > .__react_component_tooltip {
      left: 0px !important;
      top: 0px !important;
      color: ${({ theme }) => theme.colors.black} !important;
    }
  }
`;
const MenuBarButton = ({ title, menu, icon, isMobile, isActive, children }) => {
  const { activeMenu, setActiveMenu } = useContext(MenuBarContext);
  const Icon = icon;
  const handleClick= () =>{
    setActiveMenu(
      activeMenu === menu ? modalConstants.HIDDEN : menu
    )
  }
  if(!isMobile ){
    return children;
  }
  return (
    <ButtonWrapper isActive={isActive} isMobile={isMobile}>
      {Icon && (
        <span className="button">
          <a data-tip data-for={'menubutton ' + menu}>
            {isMobile?<div onClick={handleClick}>
              {title}
              </div>
              :
              <Icon
                onClick={handleClick}
              />
            }
            
          </a>
          <Tooltip
            id={'menubutton ' + menu}
            place="top"
            effect="solid"
            className="menubar-tooltip"
          >
            <span>{title}</span>
          </Tooltip>
        </span>
      )}
      {children}
    </ButtonWrapper>
  );
};
const MenuBar = ({ orientation, onSetLayout, width, isMobile }) => {
  const {
    allLayouts: { edges },
  } = useStaticQuery(graphql`
    query MyLayoutsQuery {
      allLayouts: allNodeCardInlayLayout(sort: {field_weight: ASC}) {
        edges {
          node {
            title
            field_weight
            field_inlay_json
            relationships {
              field_orientation {
                name
              }
              field_image {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  `);
  const layouts = edges
    .map(({ node }) => node)
    .filter(node => node.relationships.field_orientation.name === orientation)
    .map(node => {
      return {
        inlay_json: node.field_inlay_json,
        image: queryHelper.getChildImgPublicUrl(node),
      };
    });
  const [activeMenu, setActiveMenu] = useState(modalConstants.HIDDEN); // state to show/hide the menu
  const wrapperRef = useRef(null); // Ref to detect outside click
  useOnClickOutside(wrapperRef, () => setActiveMenu(modalConstants.HIDDEN));

  const setRef = useSwipeDetector({
        onUpSwipe: debounce(() => {
            setActiveMenu(modalConstants.LAYOUT);
        },100),
        onDownSwipe: debounce(() => {
            setActiveMenu(modalConstants.HIDDEN);
        },100),
    })

    useEffect(() => {
      if(wrapperRef?.current){
          setRef(wrapperRef?.current);
      }
    },[wrapperRef?.current]);

  /** Parse layout json  */
  const getEnumValue = v => {
    switch (v) {
      case 'Text':
        return layoutTypeConstants.TEXT;
      case 'Image':
        return layoutTypeConstants.PHOTO;
    }
  };
  const parseInlayJson = str => {
    try {
      const parsed = JSON.parse(
        str
          .replaceAll('{', '{"')
          .replaceAll(':', '":')
          .replaceAll('\r\n', '')
          .replaceAll(/\s/g, '')
      );
      const inlayLayout = [];
      parsed.map(l => {
        const layout_0 = [];
        Object.keys(l).map(key => {
          for (let i = 0; i < l[key]; i++) layout_0.push(getEnumValue(key));
        });
        inlayLayout.push(layout_0);
      });
      return inlayLayout;
    } catch (error) {
      return [];
    }
  };
  useEffect(() => {
    if (layouts.length > 0) {
      const items = parseInlayJson(layouts[0].inlay_json);
      items.length >= 2 && onSetLayout(items);
    }
  }, []);
  const handleClick = i => {
    setActiveMenu(modalConstants.HIDDEN); // Hide a menu when an user selects an object
    const items = parseInlayJson(layouts[i].inlay_json);
    items.length >= 2 && onSetLayout(items);
  };
  const isAnyMenuActive= modalConstants.LAYOUT===activeMenu;
  return (
    <Wrapper isAnyMenuActive={isAnyMenuActive} className={isMobile?'mobileWrapper layout-menu':''} ref={wrapperRef} width={width}>
      {isMobile && 
          <img  
            onClick={()=>setActiveMenu(activeMenu===modalConstants.HIDDEN?modalConstants.LAYOUT:modalConstants.HIDDEN)}
            src="/images/create-your-own/3-dot.png"
            alt=""
            className='dotImage'
        />
        }
      <MenuBarContext.Provider value={{ activeMenu, setActiveMenu }}>
        <MenuBarButton
          menu={modalConstants.LAYOUT}
          icon={LayoutIcon}
          title="Layouts"
          isMobile={isMobile}
          isActive={activeMenu === modalConstants.LAYOUT}
        >
          <Menu
            isMobile={isMobile}
            photos={layouts.map(layout => layout.image)}
            handleClick={i => handleClick(i)}
            columns={4}
            padding="5px"
            isActive={isMobile?activeMenu === modalConstants.LAYOUT:true}
            orientation={orientation}
          />
        </MenuBarButton>
      </MenuBarContext.Provider>
    </Wrapper>
  );
};
MenuBar.propTypes = {
  width: PropTypes.string,
};
MenuBar.defaultProps = {
  width: '100%',
};
export default MenuBar;
