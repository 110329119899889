import React, {
  forwardRef,
  useState,
  useContext,
  useRef,
  useEffect,
  useImperativeHandle,
  useMemo,
} from 'react';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faEye } from '@fortawesome/free-solid-svg-icons';
import { navigate } from 'gatsby';

import './preview.scss';
import { Wrapper, Title, PreviewWrapper } from './parts';
// import core components
import { MultiStepContext, UserContext } from 'contexts';
import { multiStepConstants } from '../../../../constants';
import { canvasHelper, queryHelper, multistepHelper } from 'helpers';
import { QuantitySelector, InputGroup, AnimateCard } from 'components';
import {
  getSubtotal,
  getTotal,
  getSubsum,
  getPercent,
  convertToFloat,
} from 'utils/math';
import { lineItemsService, cartItemsService, addressService } from 'services';
import { toDateStr, getNextBusinessDay, getSingPostDay } from 'utils/moment';
import { useOnClickOutside } from 'hooks';
import { createCanvasToImage } from '../inside/utils';
import LoadingGif from '../../../../../static/gifs/loading.gif';
import { document } from 'browser-monads';

const slugHelper = require('helpers/gatsbyHelper');

const {
  STEP_REVIEW,
  STEP_FRONT,
  STEP_DELIVERY,
  STEP_OPTIONS,
} = multiStepConstants;
let bItemAddedToCart = false;

const ExistentialFunc = (field, required = false, title = '') => (
  <>
    {!required && !field ? null : (
      <p className={title ? '' : 'no-line-spacing'}>
        {title && <b>{`${title}`}&nbsp;&nbsp;</b>}
        {field ? field : null
        // (required && <span className="warning">Required&nbsp;<FontAwesomeIcon icon={faExclamationCircle}/></span>)
        }
      </p>
    )}
  </>
);

const Review = forwardRef(
  (
    {
      cardOptions,
      deliveryAddress,
      deliveryUser,
      setDeliveryUser,
      deliveryOption,
      deliveryDate,
      selectedDeal,
      warnings,
      setBCartLoading,
      cartItemId,
      canvasImgs,
      isMobile,
      defaultQuantity,
    },
    ref
  ) => {
    const {
      currentStep,
      setCurrentStep,
      pageContext: {
        outlay,
        title,
        category,
        sku,        
        cardOrientation,
        cardLicenseOwner,
        slug,
      },
    } = useContext(MultiStepContext);
    /**** Preview */
    const canvasMetadata = outlay ? JSON.parse(outlay) : null;
    const { canvasWidth, canvasHeight } = canvasHelper.getCanvasSize(
      canvasMetadata
    ); // get width and height from the outlay json
    // const [bItemAddedToCart, setBItemAddedToCart] = useState(false);

    const [quantity, setQuantity] = useState(1); // Quantity of the cards

    useMemo(() => {
      if (defaultQuantity && quantity !== defaultQuantity) {
        setQuantity(defaultQuantity);
      }
    }, [defaultQuantity]);

    useEffect(() => {
      function getQuills() {
        // Below function creates clone of quill without borders and pointer events.
        const generateChild = (element, isLeft) => {
          let copyQuill = element.cloneNode(true);
          copyQuill.id = `preview-quill-${isLeft ? 'left' : 'right'}`;
          copyQuill.style.margin = '0';
          if (isLeft) {
            copyQuill.style.transform = 'rotate3d(0,1,0,180deg)';
          }
          copyQuill.style.zoom = isMobile ? '.5' : '.8';
          copyQuill.style.display = 'block';
          copyQuill.style.height = '100%';
          copyQuill.style.width = '100%';
          copyQuill.style.pointerEvents = 'none';

          // Mobile fixes
          copyQuill.querySelector('#quill-right-border')?.remove();
          copyQuill.querySelector('#quill-left-border')?.remove();
          copyQuill.querySelector('#quill-top-border')?.remove();
          copyQuill.querySelector('#quill-bottom-border')?.remove();
          copyQuill.querySelector('#toolbar-inside-right-0')?.remove();
          copyQuill.querySelector('#toolbar-inside-right-1')?.remove();
          copyQuill.querySelector('#toolbar-inside-right-2')?.remove();
          copyQuill.querySelector('#toolbar-inside-left-0')?.remove();
          const changeDisplayStatus = id => {
            for (let i = 0; i < 3; i++) {
              let el = copyQuill.querySelector(`#${id}-${i}`);
              if (el) {
                el.style.display = 'block';
              }
            }
          };
          changeDisplayStatus('inside-left');
          changeDisplayStatus('inside-right');

          let containers = copyQuill.getElementsByClassName(
            'ql-container ql-snow'
          );
          let editors = copyQuill.getElementsByClassName('ql-editor');
          if (containers?.length) {
            for (let container of containers) {
              container.style.border = 'none';
            }
          }
          if (editors?.length) {
            for (let editor of editors) {
              editor.setAttribute('contenteditable', 'false');
            }
          }
          return copyQuill;
        };

        //Selecting quills
        let leftQuill = document.getElementById('inside-left');
        let rightQuill = document.getElementById('inside-right');

        if (leftQuill) {
          let copyQuill = generateChild(leftQuill, true);
          let container = document.getElementById('quill-review-left');
          if (container) {
            container.replaceChildren(copyQuill);
          }
        }
        if (rightQuill) {
          let copyQuill = generateChild(rightQuill, false);
          let container = document.getElementById('quill-review-right');
          if (container) {
            container.replaceChildren(copyQuill);
          }
        }
      }
      if (currentStep === STEP_REVIEW) {
        getQuills();
      }
    }, [currentStep]);

    const afterDealPrice = () => {
      if (selectedDeal.field_discount_type === 'Price') {
        return getSubsum(
          _.get(cardOptions, 'field_card_size.field_price.formatted', '0'),
          selectedDeal.field_discount_value
        );
      } else {
        return getPercent(
          _.get(cardOptions, 'field_card_size.field_price.formatted', '0'),
          selectedDeal.field_discount_value
        );
      }
    };
    const getDeliveryOption = () => {
      return _.get(deliveryOption, 'name', '');
    };
    const calculateDeliveryPrice = (bFloat = false) => {
      try {
        const deliveryPriceStr = _.get(
          deliveryOption,
          'field_price.formatted',
          '0.00'
        );
        const preAddedCartItems = cartItemsService.getSameDeliveryItems(
          deliveryAddress,
          deliveryDate,
          getDeliveryOption()
        );
        if (cartItemId) {
          // Get the original cart-item data to update
          const cartItemBeforeUpdate = cartItemsService.getCartItem(cartItemId);
          if (cartItemBeforeUpdate.metadata.delivery.price !== '$0.00') {
            // in case if the original cart-item had delivery price
            if (
              cartItemsService.compareWithCartItem(
                deliveryAddress,
                deliveryDate,
                getDeliveryOption(),
                cartItemBeforeUpdate
              )
            ) {
              return bFloat
                ? convertToFloat(deliveryPriceStr)
                : deliveryPriceStr;
            }
          }
        }
        return preAddedCartItems.length === 0
          ? bFloat
            ? convertToFloat(deliveryPriceStr)
            : deliveryPriceStr
          : bFloat
          ? 0.0
          : '$0.00';
      } catch (error) {
        ////console.log(error);
        return bFloat ? 0.0 : '$0.00';
      }
    };
    const calculateSubTotal = () => {
      return getSubtotal(
        selectedDeal.field_discount_type
          ? afterDealPrice()
          : _.get(cardOptions, 'field_card_size.field_price.formatted', '0'),
        quantity
      );
    };
    const calculateTotal = () => {
      return getTotal(
        selectedDeal.field_discount_type
          ? afterDealPrice()
          : _.get(cardOptions, 'field_card_size.field_price.formatted', '0'),
        quantity,
        calculateDeliveryPrice()
      );
    };
    const initialProductData = {
      // initial object of buy-item
      itemName: title,
      itemPrice: calculateTotal(),
    };
    const [buyItem, setBuyItem] = useState(initialProductData); // state of the current buy-item
    const { user } = useContext(UserContext); // Context API to get user state

    useImperativeHandle(ref, () => ({
      handleAddToCart,
    }));

    // detect onClick of continue shopping
    const handleUnRouteChanged = routesChange => {
      setBuyItem({ ...buyItem, itemPrice: calculateTotal() });
      if (
        routesChange.from !== '/' &&
        routesChange.to === '/' &&
        bItemAddedToCart
      ) {
        bItemAddedToCart = false;
        navigate('/greeting-cards');
      }
    };
    useEffect(() => {
      setDeliveryUser(prevState => {
        return {
          ...prevState,
          email: user ? user.emailAddress : '',
        };
      });
      const unRoutechanged = Snipcart.events.on(
        'theme.routechanged',
        handleUnRouteChanged
      );

      return function cleanup() {
        unRoutechanged();
      };
    }, []);
    /** Order */
    useEffect(() => {
      // Update the price whenever quantity and delivery option changes
      setBuyItem({ ...buyItem, itemPrice: calculateTotal() });
    }, [quantity, cardOptions, deliveryOption, selectedDeal]);
    /** user */
    useEffect(() => {
      if (user) {
        setDeliveryUser(prevState => {
          return { ...prevState, email: user.emailAddress };
        });
      } else {
        setDeliveryUser(prevState => {
          return { ...prevState, email: '' };
        });
      }
    }, [user]);

    const getDeliveryDate = () => {
      switch (getDeliveryOption()) {
        case 'Next Day Delivery':
          return toDateStr(getNextBusinessDay(deliveryDate));
        case 'Same Day Delivery':
          return toDateStr(deliveryDate);
        case 'SingPost FREE (up to 4 days)':
            return toDateStr(getSingPostDay(deliveryDate));
        default:
          return '';
      }
    };

    const getSnipcartMetadata = () => {
      return {
        cardSize: {
          name: cardOptions.field_card_size.name,
          src: queryHelper.getChildImgSrc(cardOptions.field_card_size),
        },
        envelopeColor: {
          name: cardOptions.field_envelope_colour.name,
          src: queryHelper.getChildImgSrc(cardOptions.field_envelope_colour),
        },
        envelopeType: {
          name: cardOptions.field_envelope_type.name,
          src: queryHelper.getChildImgSrc(cardOptions.field_envelope_type),
        },
        price: {
          unit: _.get(cardOptions, 'field_card_size.field_price.formatted', ''),
          quantity,
          total: `$ ${calculateTotal()}`,
        },
        user: { ...deliveryUser, ...deliveryAddress },
        delivery: {
          option: getDeliveryOption(),
          dispatchDate: toDateStr(deliveryDate),
          deliveryDate: getDeliveryDate(),
          price: calculateDeliveryPrice(),
          src: queryHelper.getChildImgPublicUrl(deliveryOption),
        },
        ...(selectedDeal.title
          ? {
              offer: {
                title: selectedDeal.title,
                src: queryHelper.getChildImgSrc(selectedDeal),
                value: `${selectedDeal.field_discount_value} ${
                  selectedDeal.field_discount_type === 'Price' ? '$' : '%'
                }`,
                drupal_internal__nid: selectedDeal.drupal_internal__nid,
                field_discount_limit: selectedDeal.field_discount_limit,
                field_discount_value: selectedDeal.field_discount_value,
                field_discount_type: selectedDeal.field_discount_type,
              },
            }
          : { offer: {} }),
      };
    };
    // Preview
    const [showPreview, setShowPreview] = useState(false);
    const togglePreview = b => {
      const insideLeft = document.querySelector('.preview__panel--inside-left');
      if (b) {
        document.body.classList.toggle('open');
        insideLeft.classList.toggle('shadow-me');
      } else {
        document.body.classList.remove('open');
        insideLeft.classList.remove('shadow-me');
      }
    };
    const [activePreview, setActivePreview] = useState('front');

    const previewRef = useRef(null); // Ref to detect outside click
    useOnClickOutside(previewRef, () => {
      setShowPreview(false);
      setActivePreview('front');
    }); // handle when click outside of preview

    const handleAddToCart = async (s3Keys, quillData = ['', ''], frontJson) => {
      // When click add to cart
      try {
        // generate a unique id for s3 file naming
        let itemId = slugHelper.generateUniqueId(title);
        // upload canvases to s3 and retrive the keys array
        const lineItem = await lineItemsService.addLineItem({
          userid: '',
          lineItemId: itemId,
          cardImageThumbnailUrl: 'None',
          fabricJsonFront: s3Keys[0],
          fabricJsonInsideL: s3Keys[1],
          fabricJsonInsideR: s3Keys[2],
          quillJsonInsideL: quillData[0],
          quillJsonInsideR: quillData[1],
          optionCardSize: cardOptions.field_card_size.name,
          optionEnvelopeType: cardOptions.field_envelope_type.name,
          optionEnvelopeColour: cardOptions.field_envelope_colour.name,
          deliveryDispatchDate: toDateStr(deliveryDate),
          deliveryName: deliveryUser.fullName,
          deliveryCompany: deliveryUser.company,
          deliveryStreetAddress: deliveryAddress.address1,
          deliveryStreetAddress2: deliveryAddress.address2,
          deliveryCity: deliveryAddress.locality,
          deliveryState: deliveryAddress.state,
          deliveryPostcode: deliveryAddress.postcode,
          deliveryCountry: deliveryAddress.country,
          deliveryPhoneNumber: deliveryUser.phone,
          shippingOption: _.get(deliveryOption, 'name', 'None'),
          shippingDate: getDeliveryDate(),
          dealSelection: selectedDeal?.drupal_internal__nid,
          quantityCard: quantity,
          priceCard: convertToFloat(
            _.get(cardOptions, 'field_card_size.field_price.formatted', '0')
          ),
          priceDelivery: calculateDeliveryPrice(true),
          priceSubtotal: buyItem.itemPrice,
          priceTotal: buyItem.itemPrice,
          pdfUrlS3: 'pending',
          thumbnailOfFront: '', //canvasImgs[0]
          thumbnailOfInsideL: '', //canvasImgs[1],
          thumbnailOfInsideR: '', //canvasImgs[2],
          cardOrientation,
          cardLicenseOwner,
        });
        const itemData = {
          ...buyItem,
          itemId,
          itemUrl: `${process.env.GATSBY_API_URL}/lineitem/${lineItem.id}`,
          metadata: {
            lineItemId: lineItem.id,
            cognitoUserId: user?.cognitoUserId,
            title,
            category,
            sku,
            slug,            
            thumbnail: frontJson,
            ...getSnipcartMetadata(),
          },
        };       
        bItemAddedToCart = true;
        if (user) {
          // save address
          await addressService.addAddress({
            userId: user.cognitoUserId || user.username,
            addressType: 'billing',
            addressDefault: true,
            ...deliveryUser,
            ...deliveryAddress,
          });
          await addressService.setBillingAddressOfUser(
              user.cognitoUserId || user.username
          );
        }
        if (cartItemId) {
          const cartItemBeforeUpdate = cartItemsService.getCartItem(cartItemId);
          if (cartItemBeforeUpdate.metadata.delivery.price !== '$0.00') {
            // in case if the original cart-item had delivery price
            if (
              !cartItemsService.compareWithCartItem(
                deliveryAddress,
                deliveryDate,
                getDeliveryOption(),
                cartItemBeforeUpdate
              )
            ) {
              await cartItemsService.handleUpdateCartItem(cartItemBeforeUpdate);
            }
          }
          await cartItemsService.updateItemToCart(cartItemId, itemData);
          cartItemsService.openCart();
          setBCartLoading(false);
          // Do not remove below line, page shouldn't be able to scroll until process finish.
          document.getElementsByTagName('html')[0].style.overflow = 'auto';
        } else {
          await cartItemsService.addItemToCart(itemData);
          setBCartLoading(false);
          // Do not remove below line, page shouldn't be able to scroll until process finish.
          document.getElementsByTagName('html')[0].style.overflow = 'auto';
        }
      } catch (error) {
        console.log(error);
      }
    };
    return (
      <Wrapper
        className={currentStep !== STEP_REVIEW ? 'hidden-step' : 'active-step'}
        width={canvasWidth}
      >
        <div className="review-summary">
          <div className="thumbnail">
            <AnimateCard
              width={`${canvasWidth * (isMobile ? 0.7 : 0.8)}px`}
              height={`${canvasHeight * (isMobile ? 0.7 : 0.8)}px`}
              transform={false}
            >
              <div className="img-wrapper">
                {canvasImgs[0] && (
                  <img
                    src={canvasImgs[0]}
                    className="review-preview"
                    alt="preview of card"
                    width={canvasWidth * (isMobile ? 0.7 : 0.8)}
                    height={canvasHeight * (isMobile ? 0.7 : 0.8)}
                  />
                )}
              </div>
              <div className="info">
                <span
                  className="with-margin"
                  onClick={ev => setShowPreview(!showPreview)}
                >
                  <FontAwesomeIcon icon={faEye} />
                  Click to open
                </span>
                <span onClick={ev => setCurrentStep(STEP_FRONT)}>
                  <FontAwesomeIcon icon={faPencilAlt} />
                  Edit
                </span>
              </div>
            </AnimateCard>
          </div>

        </div>
        <PreviewWrapper
          ref={previewRef}
          className={`${showPreview ? '' : 'hidden'}`}
          width={canvasWidth * (isMobile ? 0.5 : 0.8)}
          height={canvasHeight * (isMobile ? 0.5 : 0.8)}
          bLandscape={cardOrientation === 'Landscape'}
          activePreview={activePreview}
          onClick={() => setShowPreview(false)}
        >
          <div className="preview-cont open-me">
            <div className={`preview active-${activePreview}`}>
              <div
                onClick={ev => {
                  ev.stopPropagation();
                  setActivePreview('inside-left');
                }}
                className={`preview__panel preview__panel--front`}
              >
                <div
                  className={
                    cardOrientation === 'Landscape' ? 'rotate--90' : ''
                  }
                >
                  {canvasImgs[0] && (
                    <img
                      src={canvasImgs[0]}
                      className="preview-image"
                      alt="front of preview"
                    />
                  )}
                </div>
              </div>
              <div
                onClick={ev => {
                  ev.stopPropagation();
                  if (activePreview === 'inside-left') {
                    setActivePreview('front');
                  } else {
                    setActivePreview('inside-left');
                  }
                }}
                className={`preview__panel preview__panel--inside-left`}
              >
                <div
                  className={cardOrientation === 'Landscape' ? 'rotate-90' : ''}
                >
                  {canvasImgs[1] ? (
                    <img
                      src={canvasImgs[1]}
                      className="preview-image mirror-scale"
                      alt="inside left of preview"
                    />
                  ) : (
                    <div id={'quill-review-left'}></div>
                  )}
                </div>
              </div>
              <div
                onClick={ev => {
                  ev.stopPropagation();
                  if (activePreview === 'inside-right') {
                    setActivePreview('inside-left');
                  } else {
                    setActivePreview('inside-right');
                  }
                }}
                className={`preview__panel preview__panel--inside-right`}
              >
                <div
                  className={`${
                    cardOrientation === 'Landscape' ? 'rotate--90' : ''
                  }`}
                >
                  {canvasImgs[2] ? (
                    <img
                      src={canvasImgs[2]}
                      className="preview-image"
                      alt="inside right of preview"
                    />
                  ) : (
                    <div id={'quill-review-right'}></div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </PreviewWrapper>
        {cardOptions && cardOptions.field_card_size && (
          <div className="options card">
            <div className="title">
              <Title>How much will it cost?</Title>
            </div>
            <ul>
              <li>
                <p>
                <QuantitySelector
                  quantity={quantity}
                  setQuantity={setQuantity}
                />
                <span>&nbsp;</span><span>&nbsp;</span>
                {`$ ${afterDealPrice()}`}
                &nbsp;each&nbsp;
                  {selectedDeal.field_discount_type && (
                    <span>
                        {`(${selectedDeal.field_discount_value}${
                          selectedDeal.field_discount_type === 'Price'
                            ? ''
                            : '%'
                        } discount)`}
                  </span>
                  )}
                </p>
              </li>
              <li>
                <p>
                  {calculateDeliveryPrice(true) != 0 && (
                    <span>
                        + {`$ ${convertToFloat(calculateDeliveryPrice(true))} (delivery fee)`} = &nbsp;
                    </span>
                  )}
                  Total: {`$ ${buyItem.itemPrice}`}
                </p>
              </li>
            </ul>
            <div className="title">
              <Title>Who is the recipient?</Title>
              <div className="control">
                <span onClick={ev => setCurrentStep(STEP_DELIVERY)}>
                  <FontAwesomeIcon icon={faPencilAlt} />
                  Edit
                </span>
              </div>
            </div>
            <ul>
              {ExistentialFunc(deliveryUser.fullName, true)}
              {ExistentialFunc(deliveryUser.company, false)}
              {ExistentialFunc(deliveryAddress.address1, true)}
              {ExistentialFunc(deliveryAddress.address2)}
              {ExistentialFunc(deliveryAddress.locality, true)}
              {ExistentialFunc(deliveryAddress.state)}
              {ExistentialFunc(deliveryAddress.postcode)}
              {ExistentialFunc(deliveryAddress.country, true)}
              {ExistentialFunc(
                deliveryUser.phone,
                multistepHelper.isCourierOptionSelected(deliveryOption),
              )}
            </ul>
            <div className="title">
              <Title>What are my options?</Title>
              <div className="control">
                <span onClick={ev => setCurrentStep(STEP_OPTIONS)}>
                  <FontAwesomeIcon icon={faPencilAlt} />
                  Edit
                </span>
              </div>
            </div>
            <ul>
              <li>
                {/* <StyledImage fluid={ queryHelper.getChildImgFluid(cardOptions.field_card_size) } /> */}
                <p>
                  {cardOptions.field_card_size.name} with a {cardOptions.field_envelope_colour.name}&nbsp;Envelope
                </p>
              </li>
              <li>
                <p>
                  {cardOptions.field_envelope_type.name}
                </p>
              </li>
              {/* <li><b>Card Price:&nbsp;&nbsp;{cardOptions.field_card_size.field_price.formatted}</b></li> */}
            </ul>
            <div className="title">
              <Title>How will it be delivered?</Title>
              <div className="control">
                <span onClick={ev => setCurrentStep(STEP_DELIVERY)}>
                  <FontAwesomeIcon icon={faPencilAlt} />
                  Edit
                </span>
              </div>
            </div>
            <ul>
              {/* <li><b>{deliveryOption.field_price.formatted}</b></li> */}
              <li>
                {/* <img src={ queryHelper.getChildImgPublicUrl(deliveryOption) } />  */}
                <p>
                  {getDeliveryOption()}
                </p>
              </li>
              <li>
                <p>
                  {getDeliveryDate()}
                </p>
              </li>
            </ul>
            <div className="title">
              <Title>My email address:</Title>
            </div>
            <ul>
              <p>
                <div className="email">
                  <InputGroup
                    type="text"
                    value={deliveryUser.email}
                    handleChange={ev => {
                      setDeliveryUser({ ...deliveryUser, email: ev.target.value });
                    }}
                    required={warnings.length > 0}
                  />
                </div>
              </p>
            </ul>
          </div>
        )}
      </Wrapper>
    );
  }
);

export default Review;
