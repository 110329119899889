import React, {useState, useEffect, useContext} from 'react';
import styled from '@emotion/styled';
import { useStaticQuery, graphql } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
// import core components
import { DealCard } from 'components';
import { MultiStepContext, UserContext } from 'contexts'
import { multiStepConstants } from '../../../../constants';
const { STEP_DEALS } = multiStepConstants;
import { getShortToday, toShortDate, checkDateInRange } from 'utils/moment';
import { domHelper } from 'helpers';
import { offersService, cartItemsService  } from 'services';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;
const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  &:last-of-type {
    margin-bottom: 0px;
  }
  > * {
    width: calc(50% - 35px);
    margin-right: 70px;
    margin-bottom: 70px;
    &:nth-of-type(2n) {
      margin-right: 0px;
    }
    &:last-of-type {
      margin-bottom: 0px;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    flex-direction: column;
    > div {
      width: 100%;
      margin: 0px;
      margin-bottom: 15px;
    }
  }
`;
const Title = styled.h1`
    font-weight: bold;
    font-size: 18px;
    line-height: 38px;
    > span {
      font-size: 14px;
      font-weight: 500;
      margin-left: 10px;
      margin: 0px;
    }
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      font-size: 18px;
      line-height: 28px;
    }
`;

const Deals = ({ selectedDeal, setSelectedDeal, loadedOfferId }) => {
  const { currentStep, pageContext: { category } } = useContext(MultiStepContext); // Context API for multi-step form status
  const { user } = useContext(UserContext); // Context API to get user state
  const { allDeals: { edges } } = useStaticQuery(graphql`
    query MyDealsQuery {
      allDeals: allNodeDeals(sort: {created:DESC}) {
        edges {
          node {
            drupal_id
            title
            body{
              value
            }
            field_enabled
            field_discount_type
            field_discount_value
            field_discount_limit
            field_start_date
            drupal_internal__nid
            field_end_date
            relationships {
              field_category {
                name
              }
              field_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                      formats: [WEBP]
                      layout: CONSTRAINED
                    )
                  }
                }
              }
            }
          }
        }
      }
    }`
  );

  useEffect(() => {
    clearDeal({});
    if(user) {
      filterByLimit(nodes);
    } else {
      setDeals(nodes);
    }
  }, [user])
  // select the offer with the loadedOfferId
  useEffect(() => {
    loadedOfferId && setSelectedDeal(deals.find(offer => offer.drupal_internal__nid === loadedOfferId));
  }, [loadedOfferId])
  function clearDeal() {
    setSelectedDeal({});
  }
  function filterByCategory(dealArr) {
    return dealArr.filter(deal => deal.relationships.field_category.map(field_category => field_category.name).includes(category));
  }
  function filterByDateRange(dealArr) {
    return dealArr.filter(deal => checkDateInRange(getShortToday(), toShortDate(deal.field_start_date), toShortDate(deal.field_end_date)));
  }
  async function filterByLimit(dealArr) {
    try {
      const appliedDeals = await offersService.getOffers(user.cognitoUserId); // Get the offers that were used priorly
      const resp = cartItemsService.getCartItems();
      const cartItems = resp.map(cartItem => cartItem.metadata); // Get the offers that are added to the cart

      setDeals(dealArr.filter(deal => {
        if(deal.field_discount_limit) { // Only do filerting when the offer has limit number
          let nOfferTotalUsed = 0;
          // Filter offers by appliedDeals
          if(appliedDeals) {
            const appliedDeal = appliedDeals.filter(aD => aD.offerDrupalInternalId === deal.drupal_internal__nid);
            if(appliedDeal[0]) nOfferTotalUsed = appliedDeal[0].offerDiscountUsed;
          }
          if(cartItems.length) {
            const addedDeal = cartItems.filter(cartItem => cartItem.offer.drupal_internal__nid === deal.drupal_internal__nid && deal.drupal_internal__nid !== loadedOfferId);
            nOfferTotalUsed += addedDeal.length;
          }
          return nOfferTotalUsed < deal.field_discount_limit;
        }
        return true;
      }));
    } catch (error) {
      ////console.log(error);
    }
  }
  // fetch nodes from graphql result and filter deals by category
  let nodes = edges.map(({node}) => node);
  nodes = filterByCategory(nodes);
  nodes = filterByDateRange(nodes);
  const [deals, setDeals] = useState(nodes);

  return (
    <Wrapper className={currentStep !== STEP_DEALS ? "hidden-step" : "active-step"}>
          <Title>
              Special Offers<br/>
              {!user && <span className="warning">
                  <FontAwesomeIcon icon={faExclamationCircle} />
                  &nbsp;
                  <a onClick={ev => domHelper.triggerClick("login-button")}>Sign In</a>
                  &nbsp;or&nbsp;
                  <a onClick={ev => domHelper.triggerClick("register-button")}>register</a> to access these hot offers
                </span>
              }
              {user && <span className="warning">Select one of the offers below to apply the discount to your order</span>
              }
          </Title>
          <CardWrapper>
            {deals.map((deal, i) =>
              <DealCard
                key={`deal-${i}`}
                metaData={deal}
                isDisabled={!user}
                isSelected={user && selectedDeal?.drupal_internal__nid === deal.drupal_internal__nid}
                handleCheck={v => {
                  if(!user || selectedDeal.drupal_internal__nid === v.drupal_internal__nid)
                    clearDeal();
                  else
                    setSelectedDeal(v);
                }}
              />
            )}
          </CardWrapper>
    </Wrapper>
  )
};

export default Deals;
