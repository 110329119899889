import React from 'react';

const SvgIcon = ({ onClick, position, height="16", width="16" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="currentColor"
      className="bi bi-align-bottom"
      viewBox="0 0 16 16"
      onClick={onClick}
    >
      {position === 'bottom' && (
        <>
          <rect width="4" height="12" x="6" y="1" rx="1" />
          <path d="M1.5 14a.5.5 0 0 0 0 1v-1zm13 1a.5.5 0 0 0 0-1v1zm-13 0h13v-1h-13v1z" />
        </>
      )}
      {position === 'top' && (
        <>
          <rect
            width="4"
            height="12"
            rx="1"
            transform="matrix(1 0 0 -1 6 15)"
          />
          <path d="M1.5 2a.5.5 0 0 1 0-1v1zm13-1a.5.5 0 0 1 0 1V1zm-13 0h13v1h-13V1z" />
        </>
      )}
      {position === 'center' && (
        <path d="M6 13a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v10zM1 8a.5.5 0 0 0 .5.5H6v-1H1.5A.5.5 0 0 0 1 8zm14 0a.5.5 0 0 1-.5.5H10v-1h4.5a.5.5 0 0 1 .5.5z" />
      )}
    </svg>
  );
};

export default SvgIcon;
