import React, {useState, useRef} from 'react';
import styled from '@emotion/styled';
import { useStaticQuery, graphql } from 'gatsby';
import DatePicker from "react-datepicker";
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import "react-datepicker/dist/react-datepicker.css";
// import core components
import { ControlButton } from 'components';
import { getLongDateFormat, getShortDateFormat, updateLocale } from 'utils/moment';


const Wrapper = styled.div`
    display: flex;
    margin-bottom: 15px;
`;
const Content = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Time = ({deliveryDate, setDeliveryDate}) => {
    let dateRef = null; // ref to the date input
    const { holidays: { edges } } = useStaticQuery(graphql`
        query MyTimeQuery {
            holidays: allTaxonomyTermHolidayCalendar {
                edges {
                    node {
                        name
                        field_json        
                    }
                }
            }
        }
    `);    
    try {   
        //console.log("holidays check: ", JSON.stringify(JSON.parse(edges[0].node.field_json)['sgp'][0]));     
        updateLocale('sg', {
            //holidays: "2024-05-23,2024-05-24", //YYYY-MM-DD (example)
            holidays: JSON.stringify(JSON.parse(edges[0].node.field_json)['sgp'][0]),
            holidayFormat: getShortDateFormat()
        });
    } catch (error) {
        updateLocale('sg', { holidays: [] });
        console.log(error);
    }

    return (
        <Wrapper>
            <h1 className="title">Select which day to dispatch?</h1>
            <Content>
                    <ControlButton
                        icon={faCalendar}
                        text="Select date"
                        handleClick={() => dateRef.setOpen(true)}
                        handleClose={() => setDeliveryDate(new Date())}
                    >
                        <DatePicker
                            ref={(c) => dateRef = c}
                            selected={deliveryDate}
                            dateFormat={getLongDateFormat()}
                            onChange={d => setDeliveryDate(new Date(Math.max(d, new Date())))}
                        />
                    </ControlButton>
            </Content>
        </Wrapper>
    )
};

export default Time;
