import React, { useContext, useRef } from 'react';
import styled from '@emotion/styled';
// import core components
import Menu from './MenuLegacy';
import { ActionButton } from 'components';
// import icons
import AddPhotoIcon from '/static/icons/controls/add-photo.svg';

const Wrapper = styled.div`
  > .add-photo {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 5px;
    background-color: ${({ theme }) => theme.colors.lightBlue};
  }
`;
const Library = props => {
  const filesRef = useRef(null); // ref to the file input
  const { photos, setPhotos, handleClick, isActive } = props;

  const readURIs = e => {
    if (e.target.files) {
      /* Get files in array form */
      const files = Array.from(e.target.files);

      /* Map each file to a promise that resolves to an array of image URI's */

      Promise.all(
        files.map(file => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener('load', ev => {
              resolve(ev.target.result);
            });
            reader.addEventListener('error', reject);
            reader.readAsDataURL(file);
          });
        })
      ).then(
        images => {
          /* Once all promises are resolved, update state with new image URI array */
          const tempPhotos = [...photos];
          tempPhotos.unshift(...images); // Add recently added images at the top
          setPhotos(tempPhotos);
        },
        error => {
          //console.error(error);
        }
      );
    }
  };
  const triggerFiles = () => {
    filesRef.current.click();
  };
  const deletePhoto = i => {
    const tempPhotos = [...photos];
    tempPhotos.splice(i, 1);
    setPhotos(tempPhotos);
  };
  return (
    <Wrapper className={isActive ? 'visible' : 'non-visible'}>
      <div className="add-photo">
        <input
          ref={filesRef}
          type="file"
          accept="image/gif,image/jpeg,image/jpg,image/png"
          title="Add photos"
          onChange={readURIs}
          multiple
          className="hidden"
        />
        <ActionButton
          text="Add a photo"
          icon={AddPhotoIcon}
          handleClick={triggerFiles}
        />
      </div>
      <Menu
        photos={photos}
        handleClick={i => handleClick(photos[i])}
        handleDelete={deletePhoto}
        columns={4}
        isActive={isActive}
      />
    </Wrapper>
  );
};

export default Library;
