// This file is a clone of the original Menu.jsx file, which was altered to support Algolia for the Front canvas objects.
// Since the Inside uses the previous code to generate the layout types, the two menu types were split.
// TODO: Combine the two and uses flags to determine whether to use the Algolia or legacy pagination/Drupal.

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Gallery from "react-photo-gallery";
import ReactPaginate from 'react-paginate';
// import core icons
import DeleteIcon from '/static/icons/controls/delete.svg';

const Wrapper = styled.div`
    .react-photo-gallery--gallery {
        > div {
            
            ${({isMobile, orientation, theme})=>isMobile?`
                overflow: auto;
                flex-flow: unset !important;
            `:`
                ${orientation === 'Landscape' ? 
                'flex-direction:column !important;':
                ''}
                // border-top: 1px solid ${theme.colors.black};
                // border-left: 1px solid ${theme.colors.black};
            `}
        }
    }
`;
const PaginationWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding: 5px 0px;
    background-color: ${({theme}) => theme.colors.stroke};
    ul.pagination {
        display: inline-block;
        margin: 0px;
        margin-left: -5px;
    }
    ul.pagination li {
        cursor: pointer;
        cursor: pointer;
        padding: 3px;
        margin: 0px;
        margin-right: 5px;
        display: inline-block;
        color: ${({theme}) => theme.colors.black};
        &:hover {
            color: ${({theme}) => theme.colors.blue};
        }
        &.active {
            border: 1px solid ${({theme}) => theme.colors.blue};
            color: ${({theme}) => theme.colors.blue};
        }
        &.disabled {
            visibility: hidden;
        }
        > a {
            font-weight: 600 !important;
        }
    }
`;
const MenuItemWrapper = styled.div`
    
    position: relative;
    ${({isMobile, padding, columns, theme})=>isMobile?`
        padding: 15px;
        min-width: 143px;
        // opacity: 0.5;
    `:`
    padding: ${padding};
    // width: ${100 / columns + '%'};
    width: 50px;
    // border-bottom: 1px solid ${theme.colors.black};
    // border-right: 1px solid ${theme.colors.black};
    `}
    .grid-image {
        position: absolute;
        cursor: pointer;
        margin: 0px;
        width: 100%;
        height: 100%;
    }
    > div {
        position: relative;
        width: 100%;
        padding-bottom: 100%;
        cursor: pointer;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            background: ${({theme}) => theme.colors.blackOpacity};
            opacity: 0;
            z-index: -1;
        }
        &:hover {
            &:before {
                opacity: 1;
            }
        }
    }
    img {
        background: white; color: white; 
    }
`;
const StyledIcon = styled(DeleteIcon)`
    position: absolute;
    cursor: pointer;
    right: 0;
    top: 0;
    z-index: 1000;
`;

const MenuItem = ({ index, onClick, photo, margin, handleDelete, baseIndex, direction, top, left, columns, width, padding, isMobile }) => {
    const handleClick = event => {
        onClick(event, { photo, index: baseIndex + index });
    };

    return (
        <MenuItemWrapper isMobile={isMobile} columns={columns} width={width} padding={padding}>
            <div onClick={onClick ? handleClick : null}>
                <img
                    {...photo}
                    alt="img"
                    className="grid-image"
                />
            </div>
            {handleDelete && <StyledIcon height={20} width={20} onClick={ev => handleDelete(baseIndex + index)}/>}
        </MenuItemWrapper>
    );
};

const MenuInsideLayout = props => {
    const { handleClick, handleDelete, columns, isActive, padding, isMobile, orientation } = props;
    const rowsPerPage = columns * columns;
    const [page, setPage] = useState(0);
    const photos = props.photos.map(photo => {
        return {
            src: photo.thumbnail ? photo.thumbnail : photo,
            width: 4,
            height: 3
        }
    });
    const handlePageClick = (data) => {
        setPage(data.selected);
    };
    return (
        <Wrapper orientation={orientation} isMobile={isMobile} className={isActive ? "visible" : "non-visible"} style={{width: '100%'}}>
            {photos.length > 0 &&
                <Gallery
                    photos={photos.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                    renderImage={props =>
                        <MenuItem
                            { ...{...props, columns: columns, padding: padding} }
                            baseIndex={rowsPerPage * page}
                            handleDelete={handleDelete}
                            isMobile={isMobile}
                        />
                    }
                    onClick={(ev, {photo, index}) => handleClick(index)}
                />
            }
            {photos.length > rowsPerPage && <PaginationWrapper>
                <ReactPaginate
                    previousLabel='Prev'
                    nextLabel='Next'
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={Math.ceil(photos.length / rowsPerPage)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                />
            </PaginationWrapper>}
        </Wrapper>
    )
};

MenuInsideLayout.propTypes = {
    columns: PropTypes.number,
    width: PropTypes.string,
    padding: PropTypes.string,
};
MenuInsideLayout.defaultProps = {
    padding: "0px",
    columns: 4,
};

export default MenuInsideLayout;
