const { getCode, getName, overwrite } = require('country-list');

overwrite([
    {
        code: 'US',
        name: 'United States'
    },
    {
        code: 'GB',
        name: 'United Kingdom'
    }
])
export const toAlpha2Code = name => {
    return getCode(name);
}