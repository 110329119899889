import React from 'react';
import styled from '@emotion/styled';
import PhotoUploadIcon from '/static/icons/controls/photo-upload.svg';
import DeleteIcon from '/static/icons/controls/delete.svg';
import { BorderComponent } from '../QuillContainer';
import { canvasHelper } from '../../../../../helpers/canvasHelper';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  ${({ isMobile }) =>
    isMobile
      ? `
    margin: 10px !important;
  `
      : ``}
  canvas {
    border: 1px solid lightGrey;
    box-sizing: border-box;
  }
  .canvas-wrapper {
    outline: none;
  }
  .loading-gif {
    position: absolute;
  }
`;
const PhotoButton = styled.button`
  height: ${({ height }) => height + 'px'};
  position: absolute;
  top: ${({ top }) => top + 'px'};
  left: ${({ left }) => left + 'px'};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  color: ${({ theme }) => theme.colors.black};
  &:hover {
    color: ${({ theme }) => theme.colors.blue};
  }
  cursor: pointer;
`;

const PHOTO_BUTTON_HEIGHT = 60;
const SPACING_X = 5,
  SPACING_Y = 5;
class PhotoContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false, // flag to show loading gif
      images: null,
    };
    this.canvas = null; // canvas object
    // bind methods to this context
    this.toJson = this.toJson.bind(this);

    this.loadFromJson = this.loadFromJson.bind(this);
    this.setLoadingStatus = this.setLoadingStatus.bind(this);
    this.fileRef = React.createRef();
    this.onAddPhoto = this.onAddPhoto.bind(this); // handler when click Photo Add Button
    this.addPhoto = this.addPhoto.bind(this); // method when the image is loaded
    this.removePhoto = this.removePhoto.bind(this); // handler when click Delete Button
    this.readURI = this.readURI.bind(this);
    this.selectedIndex = -1; // index of which photo button is clicked
    this.getUnitSize = this.getUnitSize.bind(this);
    this.mapImagesToCanvas = this.mapImagesToCanvas.bind(this);
    this.firstImageTop = 0;
  }
  componentDidMount() {
    const fabric = window.fabric;
    // initialize the canvas state with ref
    this.canvas = new fabric.Canvas(this.c, {
      selection: false,
      preserveObjectStacking: true,
      controlsAboveOverlay : true,
    });
    this.setLayout(this.props.count);

    const oldJson = this.props.savedCanvas?.json;
    if(oldJson && canvasHelper.isCanvasJson(oldJson) && oldJson.objects.length===this.props.count){
      this.setState({images:oldJson.objects})
      this.loadFromJson(oldJson)
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.count !== nextProps.count) {
      this.setLayout(nextProps.count);
    }
    const oldJson = this.props.savedCanvas?.json;
    const newJson = nextProps.savedCanvas?.json;

    const isJsonChanged = oldJson !=newJson;
    const isCountChanged = this.props.count !=nextProps.count;
    if(canvasHelper.isCanvasJson(newJson) && (isJsonChanged || (isCountChanged && newJson.objects.length===nextProps.count))){
      this.setState({images:newJson.objects})
      this.loadFromJson(newJson)
    }
  }
  getUnitSize(count = this.props.count) {
    const { width, height } = this.props;
    const unitHeight = (height - (count - 1) * SPACING_Y) / count; // height of an each section
    const unitWidth = (width - (count - 1) * SPACING_X) / count; // height of an each section
    return { unitHeight, unitWidth };
  }
  setLayout(count) {
    this.setState({ images: new Array(count).fill(null) });
    this.canvas.clear();
  }

  setLoadingStatus(b) {
    this.setState({ isLoading: b });
  }
  toJson() {
    const json = JSON.stringify(this.canvas);
    return json;
  }
  toDataURL() {
    const data = this.canvas.toDataURL('image/png', 0.7);
    return data;
  }
  loadFromJson(json, rw = true) {
    const canvas = this.canvas;
    canvas.loadFromJSON(json, canvas.renderAll.bind(canvas), function(
      o,
      object
    ) {
      object.set('selectable', rw);
    });
  }
  addPhoto(imageData) {
    const { images } = this.state;
    images[this.selectedIndex] = imageData;
    this.canvas.remove(this.getObject(this.selectedIndex===0));
    const newImages = new Array(this.props.count).fill(null);
    newImages[this.selectedIndex] = imageData;
    this.mapImagesToCanvas(newImages);
    this.setState({ images });
    this.selectedIndex = -1;
    this.fileRef.current.value = '';
  }
  onAddPhoto(index) {
    this.selectedIndex = index;
    this.fileRef.current.click();
  }

  getObject= (isFirst)=>{
    const objects = this.canvas.getObjects();
    return objects.find(object=>{
      if(isFirst) {
        return object.top === this.firstImageTop;
      } else {
        return object.top !== this.firstImageTop;
      }
    })

  }

  removePhoto(index) {
    const images = this.state.images;
    images[index] = null;
    // this.mapImagesToCanvas(images);
    this.canvas.remove(this.getObject(index===0));
    this.setState({ images });
  }
  mapImagesToCanvas(images) {
    // this.canvas.clear();

    const { width, height } = this.props;
    if (images?.filter(imageUrl => imageUrl)?.length) {
      const { unitWidth, unitHeight } = this.getUnitSize(images.length);
      const fabric = window.fabric;
      this.setLoadingStatus(true);
      for (let i = 0; i < images.length; i++) {
        if (images[i]) {
          if(typeof images[i] !=='string'){
            this.setLoadingStatus(false);
           continue;
          }
          fabric.Image.fromURL(
            images[i],
            img => {

              let scale = 0;
              let left = 0;
              let top = (unitHeight + SPACING_Y) * i;
              if(img.width>img.height){
                scale = width / img.width;
                top+= (unitHeight-img.height*scale)/2
              } else {
                scale = unitHeight / img.height;
                left= (width-img.width*scale)/2
              }
              if (i === 0) {
                this.firstImageTop = top;
              }
              img.crossOrigin = 'Anonymous';
              img.set({
                scaleX: scale,
                scaleY: scale,
                top: top,
                left: left,
                selectable: false,
                lockMovementX: true,
                lockMovementY: true,
                lockRotation: true,
                lockScalingX: true,
                lockScalingY: true,
              });
              this.setLoadingStatus(false);
              this.canvas.add(img);
            },
            { crossOrigin: 'Anonymous' }
          );
        }
      }
    }
  }
  readURI(e) {
    try {
      var file = e.target.files[0];
      const reader = new FileReader();
      const _self = this;
      if (_self.selectedIndex < 0) throw new Error('invalid selection');
      reader.addEventListener('load', ev => {
        this.addPhoto(ev.target.result);
      });
      reader.readAsDataURL(file);
    } catch (error) {
      ////console.log(error);
    }
  }
  render() {
    const { width, height, id } = this.props;
    const { images, isLoading } = this.state;
    const { unitWidth, unitHeight } = this.getUnitSize(images?.length || 1);
    const { isActive, isMobile, canSwip, swipEvents } = this.props;

    const fabricScript = require('/static/libs/fabric.min.js');
    let style = {};
    if (!isActive && isMobile) {
      style = { display: 'none' };
    }
    return (
      <Wrapper style={style} isMobile={isMobile}>
        <BorderComponent
          isMobile={isMobile}
          canSwip={canSwip}
          {...swipEvents}
        />
        <div className="canvas-wrapper" tabIndex="0">
          <canvas
            ref={c => (this.c = c)}
            id={id}
            width={width}
            height={height}
          />
        </div>
        <input
          ref={this.fileRef}
          type="file"
          accept="image/gif,image/jpeg,image/jpg,image/png"
          title="Add photo"
          onChange={this.readURI}
          className="hidden"
        />
        {images?.map((item, i) =>
          item ? (
            <React.Fragment key={`inside-added-photo-button-${i}`}>
              <PhotoButton
                height={PHOTO_BUTTON_HEIGHT}
                top={(unitHeight + SPACING_Y) * i + SPACING_Y}
                left={SPACING_X}
                onClick={ev => this.removePhoto(i)}
              >
                <DeleteIcon />
              </PhotoButton>
            </React.Fragment>
          ) : (
            <PhotoButton
              key={`add-photo-button-${i}`}
              height={PHOTO_BUTTON_HEIGHT}
              top={
                (unitHeight + SPACING_Y) * i +
                unitHeight / 2 -
                PHOTO_BUTTON_HEIGHT / 2
              }
              onClick={ev => this.onAddPhoto(i)}
            >
              <PhotoUploadIcon />
            </PhotoButton>
          )
        )}
      </Wrapper>
    );
  }
}

export default PhotoContainer;
