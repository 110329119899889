import html2canvas from 'html2canvas';
import { document } from 'browser-monads';

export const quillToCanvasJson = (vAlign, content, config) => {
  const fabric = window.fabric;
  let canvas = new fabric.Canvas(config.canvasId, {
    top: 0,
    left: 0,
    originY: 'top',
    width: config.width,
    height: config.height,
    backgroundColor: 'white',
    selectable: false,
  });

  canvas.renderAll();
  if (config.isImage) {
    const dataUrl = canvas.toDataURL('image/png');
    canvas.dispose();
    return dataUrl;
  }
  let canvasJson = JSON.stringify(JSON.stringify(canvas.toJSON()));
  canvas.dispose();
  return canvasJson;
};

export const createCanvasToImage = async () => {
  // Prepare quills
  window.scroll(0, 0);
  document.getElementsByTagName('html')[0].style.overflow = 'hidden';
  let insidePage = document.getElementById('inside-step');
  if (insidePage) {
    insidePage.classList.remove('hidden-step');
    insidePage.classList.add('active-step', 'create-canvas');
  }

  const HEIGHT = 661;
  const WIDTH = 500;

  // create new elements
  let leftElements = document.createElement('div');
  let rightElements = document.createElement('div');
  // find quill containers
  let leftContainer = document.getElementById('inside-left');
  let rightContainer = document.getElementById('inside-right');
  // find quils from quill containers
  let leftQuils = leftContainer?.getElementsByClassName('ql-container ql-snow');
  let rightQuils = rightContainer?.getElementsByClassName(
    'ql-container ql-snow'
  );

  const handleQuilElement = quil => {
    // This function is edits quil elements for canvas render
    let clone = quil.cloneNode(true);
    let clipboards = clone.getElementsByClassName('ql-clipboard');
    let tooltips = clone.getElementsByClassName('ql-tooltip');
    if (clipboards?.length) {
      for (let clipboard of clipboards) {
        clipboard.remove();
      }
    }
    if (tooltips?.length) {
      for (let tooltip of tooltips) {
        tooltip.remove();
      }
    }
    clone.style.border = 'none';
    return clone;
  };

  if (leftQuils?.length) {
    for (let quil of leftQuils) {
      let editedQuil = handleQuilElement(quil);
      leftElements.appendChild(editedQuil);
    }
    leftElements.style.cssText = `
      height: ${leftContainer.offsetHeight || rightContainer.offsetHeight}px;
      width: ${leftContainer.offsetWidth || rightContainer.offsetWidth}px;
      display: flex;
      flex-direction: column;
      position: absolute;
      left:0;
      top:3000px;
    `;
    document.body.appendChild(leftElements);
  }

  if (rightQuils?.length) {
    for (let quil of rightQuils) {
      let editedQuil = handleQuilElement(quil);
      rightElements.appendChild(editedQuil);
    }
    rightElements.style.cssText = `
      height: ${rightContainer.offsetHeight || leftContainer.offsetHeight}px;
      width: ${rightContainer.offsetWidth || leftContainer.offsetWidth}px;
      display: flex;
      flex-direction: column;
      position: absolute;
      left:0;
      top:3000px;
    `;
    document.body.appendChild(rightElements);
  }
  let res = {};
  const createImage = async element => {
    try {
      let clientRect = element.getBoundingClientRect();
      let elementWidth = clientRect.width;
      let elementHeight = clientRect.height;
      let canvas = await html2canvas(element, {
        width: elementWidth,
        height: elementHeight,
        scale: 2,
      });
      // remove created divs
      element.remove();

      /*
      var a = document.createElement('a');
      a.href = canvas.toDataURL('image/png');
      a.download = 'myfile.png';
      a.click();
       */

      let canvasJSON = {
        version: '4.3.1',
        objects: [
          {
            type: 'image',
            version: '4.3.1',
            originX: 'left',
            originY: 'top',
            left: 0,
            top: 0,
            width: elementWidth,
            height: elementHeight,
            fill: 'rgb(0,0,0)',
            stroke: null,
            strokeWidth: 0,
            strokeDashArray: null,
            strokeLineCap: 'butt',
            strokeDashOffset: 0,
            strokeLineJoin: 'miter',
            strokeUniform: false,
            strokeMiterLimit: 4,
            scaleX: 1,
            scaleY: 1,
            angle: 0,
            flipX: false,
            flipY: false,
            opacity: 1,
            shadow: null,
            visible: true,
            backgroundColor: '',
            fillRule: 'nonzero',
            paintFirst: 'fill',
            globalCompositeOperation: 'source-over',
            skewX: 0,
            skewY: 0,
            cropX: 0,
            cropY: 0,
            src: canvas.toDataURL(),
            crossOrigin: null,
            filters: [],
          },
        ],
      };

      //console.log(canvasJSON);

      return JSON.stringify(JSON.stringify(canvasJSON));
    } catch (e) {
      ////console.log(e);
      return false;
    }
  };

  let promises = [];
  if (leftElements.hasChildNodes()) {
    promises.push(createImage(leftElements));
  }
  if (rightElements.hasChildNodes()) {
    promises.push(createImage(rightElements));
  }
  let results = await Promise.all(promises);
  if (results.length === 2) {
    res = {
      firstPage: results[0],
      secondPage: results[1],
    };
  } else if (results.length === 1) {
    if (leftElements.hasChildNodes()) {
      res = {
        firstPage: results[0],
      };
    } else {
      res = {
        secondPage: results[0],
      };
    }
  }
  // changing quill classes as hidden
  let quillclasses = document.getElementsByClassName(
    'active-step create-canvas'
  )[0];
  if (quillclasses) {
    quillclasses.classList.add('hidden-step');
    quillclasses.classList.remove('active-step', 'create-canvas');
  }
  return res;
};
