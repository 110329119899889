import React, { useState } from 'react';
import styled from '@emotion/styled';
// import core components
import { SearchBox } from '../../../../algolia';
import FilterIcon from '/static/icons/search-page/filter.svg';
import CloseIcon from '/static/icons/controls/close.svg'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  > .filter-search {
    width: 100%;
    .filter-icon {
      display: none;
    }
    ${({isMobile})=>isMobile?`
      input {
        font-size: 8px !important;
        padding: 5px !important;
      }
    `:``} 
  }
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    display: block;
    padding: 20px;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 5px;
    > .filter-search {
      // display: flex;
      // width: 100%;
      // justify-content: space-between;
      .filter-icon {
        // display: flex;
        // flex-direction: row;
        // align-items: center;
        h3 {
          // margin: 0 !important;
          // padding-left: 12px;
        }
      }
    }
  }
`;
const Title = styled.h1`
  font-weight: bold;
  line-height: 38px;
  margin-bottom: 0;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    margin-bottom: 20px;
    font-size: 24px;
  }
`;
const StyledSearchBox = styled(SearchBox)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0;
  margin-left: auto;
  width: 100%;
`;

const StyledButton = styled.button`
  display: none;
  background-color: ${({ theme }) => theme.colors.white};
  border-style: none;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: block;
  }
`;
const Header = ({ keyword, category, bShowFilter, setBShowFilter, isMobile, onChange }) => {
  return (
    <Wrapper isMobile={isMobile}>
      <div className="filter-search">
        <StyledSearchBox autoFocus defaultRefinement={keyword} onChange={onChange}  />
        {bShowFilter || <div className="filter-icon" onClick={ev => setBShowFilter(true)}>
          <FilterIcon />
          <StyledButton>Filters</StyledButton>
        </div>}
        {bShowFilter && <div className="filter-icon" onClick={ev => setBShowFilter(false)}>
          <CloseIcon />
        </div>}
      </div>
    </Wrapper>
  );
};

export default Header;
