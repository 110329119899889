import React, { useState, useContext, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { useStaticQuery, graphql } from 'gatsby';
// import core components
import MenuBar from './MenuBar';
import ToolBar from './ToolBar';
import Canvas from '../canvas';
import Library from '../Library';
import { MultiStepContext, UserContext } from 'contexts';
import {
  multiStepConstants,
  modalConstants,
  userActionConstants,
} from '../../../../constants';
import { canvasHelper, localstorageHelper } from 'helpers';
import { useOnClickOutside } from 'hooks';
import RedoIcon from '/static/icons/toolbar/redo.svg';
import UndoIcon from '/static/icons/toolbar/undo.svg';
import MobilePhotoUpload from '../../../MobilePhotoUpload/MobilePhotoUpload';

const { STEP_FRONT } = multiStepConstants;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  outline: none;
  &.active-step {
    display: flex;
  }
  .separator {
    position: relative;
  }

  .photo-library {
    top: 0px;
    left: calc(50% - 300px);
    position: absolute;
    width: 600px;
    z-index: 1000;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: ${({ theme }) => theme.shadow.menu};
    transition: ${props => props.theme.transitions.default};
    opacity: 0;
    visibility: hidden;

    &.active {
      opacity: 1;
      visibility: visible;
    }
  }
`;

const Front = ({
  canvasRef,
  savedCanvas,
  design_id,
  path,
  isMobile,
  onSaveDesign,
}) => {
  const {
    currentStep,
    pageContext: { outlay, card_type, galleries, category, title },
    library,
    setLibrary,
  } = useContext(MultiStepContext); // Context API for multi-step form status
  const { user, createNotification } = useContext(UserContext); // Context API to get user state
  const [objectsOnCanvas, setObjectssOnCanvas] = useState([]); // state to manage the photos that are added to the canvas
  const [showLibrary, setShowLibrary] = useState(false); // show / hide library when an user uploads background image
  const [textObject, setTextObject] = useState({
    text: '',
    textInstance: null,
  });
  const libraryRef = useRef(null); // Ref to detect outside click
  useOnClickOutside(libraryRef, () => setShowLibrary(false));
  const canvasMetadata = outlay ? JSON.parse(outlay) : null;
  const canvasBgColor = canvasMetadata?.backgroundColor;
  const { canvasWidth, canvasHeight } = canvasHelper.getCanvasSize(
    canvasMetadata
  ); // get width and height from the outlay json
  // Set background image of the canvas if the card_type is standard
  const canvasBgImage = canvasHelper.getBackgroundImgUrl(canvasMetadata); // Get the public image url concating the base URL
  // Set overlay image of the canvas if the card_type is photo
  const canvasOverlayImage = canvasHelper.getOverlayImgUrl(canvasMetadata); // Get the public image url concating the base URL
  let saveDesignTimer; // interval id
  // Query the layouts for front-step
  const {
    allNodeCardOutlayLayout: { edges },
  } = useStaticQuery(graphql`
    query MyFrontStepQuery {
      allNodeCardOutlayLayout {
        edges {
          node {
            drupal_id
            relationships {
              field_card_type {
                name
              }
              field_galleries {
                name
                field_json
                field_base_url
              }
            }
          }
        }
      }
    }
  `);
  useEffect(() => {
    if (design_id) localstorageHelper.saveDesignId(design_id);
    else localstorageHelper.clearDesignId();
  }, [design_id]);

  useEffect(() => {
    if (isMobile && canvasRef.current) {
      // canvasRef.current.setZoom(0.5);
      canvasRef.current.setFitToScreen();
    } else if (canvasRef.current) {
      canvasRef.current.setZoom(1);
      canvasRef.current.canvas.setWidth(canvasWidth);
      canvasRef.current.canvas.setHeight(canvasHeight);
    }
  }, [isMobile, canvasRef]);
  // Extract the layout from the query result
  let layout = {};
  if (['Blank Portrait', 'Photo Canvas'].includes(card_type)) {
    layout['Photo upload'] = null;
  }
  try {
    const galleries = edges
      .map(edge => edge.node)
      .filter(node =>
        node.relationships.field_card_type.name.includes(card_type)
      )[0].relationships.field_galleries;
    galleries.map(gallery => {
      layout[gallery.name] = gallery.field_json;
    });
  } catch (error) {
    // layout = {};
  }
  const isJustPhotoUploadAvailable =
    layout.hasOwnProperty('Photo upload') && Object.keys(layout)?.length === 1;
  // Extract toolbar buttons from the galleries context
  const toolbar = galleries.map(gallery => gallery.name);
  const deleteCanvasObject = () => {
    canvasRef.current.deleteObjects();
  };
  const undoCanvas = () => {
    canvasRef.current.undo();
  };
  const redoCanvas = () => {
    canvasRef.current.redo();
  };
  const handleMenuBarClick = (item, object) => {
    // Handler when a user clicks an objct on menubar
    switch (item) {
      case modalConstants.SCENE:
        canvasRef.current.setBgImage(object);
        break;
      default:
        setObjectssOnCanvas(objectsOnCanvas.concat(object));
        break;
    }
  };
  const handleToolBarClick = ({ action, data, text = 'Add Text' }) => {
    // Handler when a user clicks an objct on toolbar
    switch (action) {
      case userActionConstants.ADD_TEXT:
        canvasRef.current.addOutlayText(text);
        break;
      case userActionConstants.EDIT_TEXT:
        textObject.textInstance.set('text', text);
        textObject.canvas.renderAll();
        break;
      case userActionConstants.PICK_COLOR:
        canvasRef.current.setBgColor(data.rgb);
        break;
      case userActionConstants.SHOW_GRID:
        canvasRef.current.showGrid(data);
        break;
      case userActionConstants.DELETE:
        deleteCanvasObject();
        break;
      case userActionConstants.DELETE_ALL:
        canvasRef.current.deleteAll();
        break;
      case userActionConstants.REDO:
        redoCanvas();
        break;
      case userActionConstants.UNDO:
        undoCanvas();
        break;
      case userActionConstants.SELECT_LAYER:
        canvasRef.current.selectLayer(data);
        break;
      case userActionConstants.ROTATE_LEFT:
        canvasRef.current.rotateLeft();
        break;
      case userActionConstants.ROTATE_CUSTOM:
        canvasRef.current.rotateCustom(data);
        break;
      case userActionConstants.CROP_IMAGE:
        canvasRef.current.cropImage(data);
        break;
      case userActionConstants.ZOOM:
        canvasRef.current.zoomActiveObject(data);
        break;
      case userActionConstants.ROTATE_RIGHT:
        canvasRef.current.rotateRight();
        break;
    }
  };

  return (
    <Wrapper
      className={currentStep !== STEP_FRONT ? 'hidden-step' : 'active-step'}
    >
      {/* {!user && <span className="warning">
          <FontAwesomeIcon icon={faExclamationCircle} />
          &nbsp;
          <a onClick={ev => domHelper.triggerClick("login-button")}>Sign In</a>
          &nbsp;or&nbsp;
          <a onClick={ev => domHelper.triggerClick("register-button")}>register</a> to access these hot offers
        </span>
        } */}
      {!isMobile && (
        <MenuBar
          isMobile={isMobile}
          layout={layout}
          onClick={handleMenuBarClick}
          width={`${canvasWidth}px`}
        />
      )}
      {isMobile && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '10px',
            alignItems: 'center',
          }}
        >
          {isJustPhotoUploadAvailable && (
            <MobilePhotoUpload
              styles={{ textAlign: 'center', marginRight: '10px' }}
              setActiveMenu={() => {}}
              isAnyMenuActive={true}
              onCrop={percentage => {
                handleToolBarClick({
                  action: userActionConstants.CROP_IMAGE,
                  data: percentage, // angle
                });
              }}
              onRotate={angle => {
                handleToolBarClick({
                  action: userActionConstants.ROTATE_CUSTOM,
                  data: angle, // angle
                });
              }}
              onZoom={zoomLevel => {
                handleToolBarClick({
                  action: userActionConstants.ZOOM,
                  data: zoomLevel, // angle
                });
              }}
              canvasRef={canvasRef}
              onSelect={object => {
                handleMenuBarClick(modalConstants.LIBRARY, object);
              }}
            />
          )}
          {['Blank', 'Photo Canvas'].includes(card_type) && (
            <div style={{ marginTop: '10px', overflow: 'hidden' }}>
              <div style={{ marginRight: '10px', float: 'left' }} onClick={undoCanvas}>
                <UndoIcon style={{ height: '20px', width: '20px' }} />
              </div>
              <div style={{ overflow: 'hidden' }} onClick={redoCanvas}>
                <RedoIcon style={{ height: '20px', width: '20px' }} />
              </div>
            </div>
          )}
        </div>

      )}
      <div className="separator">
        <Canvas
          ref={canvasRef}
          isMobile={isMobile}
          id="outlay-canvas"
          bgColor={canvasBgColor}
          width={canvasWidth}
          height={canvasHeight}
          bgImage={canvasBgImage}
          overlayImage={canvasOverlayImage}
          objects={objectsOnCanvas}
          hasGrid={true}
          handleClick={i => setShowLibrary(true)}
          savedCanvas={savedCanvas}
          onTextEditSelect={(text, textInstance, canvas) =>
            setTextObject({ text, textInstance, canvas })
          }
        />
        {!isMobile && (
          <ToolBar
            onSaveDesign={onSaveDesign}
            onTextEditSelect={text => setTextObject({ text })}
            oldText={textObject.text}
            isMobile={isMobile}
            onClick={handleToolBarClick}
            toolbar={toolbar}
          />
        )}
      </div>
      {isMobile && card_type !== 'Standard Canvas' && (
        <MenuBar
          isMobile={isMobile}
          layout={layout}
          onClick={handleMenuBarClick}
          onToolbarClick={handleToolBarClick}
          canvasRef={canvasRef}
          width={`${canvasWidth}px`}
          toolbarProps={{
            onTextEditSelect: text => setTextObject({ text }),
            oldText: textObject.text,
            isMobile: isMobile,
            onClick: handleToolBarClick,
            toolbar: toolbar,
          }}
        />
      )}
      <div
        ref={libraryRef}
        className={showLibrary ? 'photo-library active' : 'photo-library'}
      >
        <Library
          isActive={showLibrary}
          photos={library}
          setPhotos={setLibrary}
          handleClick={obj => {
            setShowLibrary(false);
            canvasRef.current.setBgImagePart(0, obj);
          }}
        />
      </div>
    </Wrapper>
  );
};

export default Front;
