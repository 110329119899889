import React, {useContext, useState} from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
// import core components
import { StepperButton, StepArrowButton } from "components";
import { MultiStepContext } from "contexts";
import { multiStepConstants } from '../../../constants';

const { // import constants for several steps
    STEP_FRONT,
    STEP_REVIEW,
    STEP_INSIDE,
    STEP_OPTIONS,
    STEP_DEALS,
    STEP_DELIVERY
} = multiStepConstants;
// import stepper bar icons
import PrevIcon from '/static/icons/stepper-bar/left-arrow.svg';
import NextIcon from '/static/icons/stepper-bar/right-arrow.svg';
import FrontIcon from '/static/icons/stepper-bar/front.svg';
import InsideIcon from '/static/icons/stepper-bar/inside.svg';
import OptionsIcon from '/static/icons/stepper-bar/options.svg';
import DeliveryIcon from '/static/icons/stepper-bar/delivery.svg';
import DealsIcon from '/static/icons/stepper-bar/deals.svg';
import ReviewIcon from '/static/icons/stepper-bar/review.svg';
import BuyIcon from '/static/icons/nav/cart.svg';
import { useEffect } from 'react';

const Wrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;

    @media (max-width: ${props => props.theme.breakpoints.s}) {
        padding: 10px 20px; 
    }
`
const StepperButtonsWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    > div {
        ${props => props.isMobile ? '' : 'margin-right: 30px;'};
    }
    > div:last-of-type {
        margin-right: 0px;
    }
    margin-left: 100px;
    margin-right: 100px;

    @media (max-width: ${props => props.theme.breakpoints.m}) {
        width: 100%;
        margin: 0px;
        > div {
        }
        > div:last-of-type {
            margin-right: 0px;
        }
    }
`;
const StepperBar = ({onBuy, warnings, bCartLoading, cartItemId,isMobile }) => {
    const steppers = [
        {step: STEP_FRONT, text: "Front", icon: FrontIcon},
        {step: STEP_INSIDE, text: "Inside", icon: InsideIcon},
        {step: STEP_OPTIONS, text: "Options", icon: OptionsIcon},
        {step: STEP_DELIVERY, text: "Delivery", icon: DeliveryIcon},
        {step: STEP_DEALS, text: "Offers", icon: DealsIcon},
        {step: STEP_REVIEW, text: "Review", icon: ReviewIcon},
    ];
    const { currentStep, setCurrentStep } = useContext(MultiStepContext); // Context API for multi-step form status
    const moveStep = step => {
        setCurrentStep(step);
    }
    const [errors, setErrors] = useState({});
    useEffect(() => {
        if(warnings.length > 0) {
            if(warnings.indexOf("Email is invalid") > -1) {
                if(warnings.length > 1) setErrors({[STEP_REVIEW]: true, [STEP_DELIVERY]: true});
                else setErrors({[STEP_REVIEW]: true});
            } else setErrors({[STEP_DELIVERY]: true});
        } else setErrors({});
    }, [warnings])

    const FaCircleIcon = <FontAwesomeIcon icon={faCircle}/>;
    return (
        <Wrapper>
            {/* Render Previous Button */}
            <StepArrowButton
                isMobile={isMobile}
                icon={PrevIcon}
                text="Back"
                isDisabled={currentStep <= STEP_FRONT}
                handleClick={() => moveStep(Math.max(STEP_FRONT, currentStep - 1))}
            />
            {/* Render Stepper Buttons */}
            <StepperButtonsWrapper isMobile={isMobile}>
                {steppers && steppers.map((stepper,index) =>
                    <StepperButton
                        isCompleted={(index+1)<currentStep}
                        isMobile={isMobile}
                        key={stepper.step}
                        metaData={stepper}
                        handleClick={() => moveStep(stepper.step)}
                        isActive={stepper.step === currentStep}
                        isError={errors[stepper.step]}
                    />
                )}
            </StepperButtonsWrapper>
            {/* Render Next Button */}
            {currentStep === STEP_REVIEW &&
                <StepArrowButton
                    icon={BuyIcon}
                    leftIcon={false}
                    text={cartItemId?"Update":"Add"}
                    handleClick={onBuy}
                    disabled={bCartLoading}
                />
            }
            {currentStep !== STEP_REVIEW &&
            <StepArrowButton
                icon={NextIcon}
                isMobile={isMobile}
                leftIcon={false}
                text={"Next"}
                handleClick={() => moveStep(currentStep + 1)}
            />}
        </Wrapper>
    )
};

export default StepperBar;
