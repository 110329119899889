import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Gallery from "react-photo-gallery"; 
import { InstantSearch, connectHits, SearchBox, Configure, Pagination, connectPagination } from 'react-instantsearch-dom';
// import core components
import algoliaInfo from '/config/algolia.js';
import DeleteIcon from '/static/icons/controls/delete.svg';
import Header from './front/objectSearch/Header';
import _ from 'lodash';
import Filter from './front/objectSearch/Filter';
import { useVisibility } from 'hooks';

const Wrapper = styled.div`
    .react-photo-gallery--gallery {
        ${({isMobile})=> isMobile?`
            // overflow: auto;
            // max-height: 139px;
        `:``}
        > div {
            border-top: 1px solid ${({theme}) => theme.colors.grey};
            border-left: 1px solid ${({theme}) => theme.colors.grey};
            ${({isMobile})=> isMobile?`
                align-items: center;
                justify-content: space-evenly;
        `:``}
        }
    }
    .ais-Pagination {
        padding-top: 5px;
    }
`;

const Title = styled.div`
    width:100%;
    text-align: center;
    width: 100%;
    text-align: center;
    padding: 10px;
    color: #5095BF;
    font-weight: 700;
    font-size: 14px;
    font-family: 'Montserrat';
`;
const MenuItemWrapper = styled.div`
    ${({theme,isMobile,columns,padding})=> isMobile?`

    .grid-image {
        max-width: 50px;
        height: 50px;
        margin: 5px;
    }

    `:`
        width: ${100 / columns + '%'};
        border-bottom: 1px solid ${theme.colors.grey};
        border-right: 1px solid ${theme.colors.grey};
        position: relative;
        padding: ${padding};
        .grid-image {
            position: absolute;
            cursor: pointer;
            margin: 0px;
            width: 100%;
            height: 100%;
        }
        > div {
            position: relative;
            width: 100%;
            padding-bottom: 100%;
            cursor: pointer;
            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 100%;
                background: ${({theme}) => theme.colors.blackOpacity};
                opacity: 0;
                z-index: 1000;
            }
            &:hover {
                &:before {
                    opacity: 1;
                }
            }
        }
    `}
    img {
        background: white; color: white; 
    }
`;
const StyledIcon = styled(DeleteIcon)`
    position: absolute;
    cursor: pointer;
    left: 10px;
    top: 10px;
    z-index: 1000;
`;

const MenuItem = ({ index, onClick, photo, margin, handleDelete, direction, top, left, columns, width, padding, isMobile }) => {
    const handleClick = event => {
        onClick(event, { photo, index });
    };

    return (
        <MenuItemWrapper isMobile={isMobile} columns={columns} width={width} padding={padding}>
            <div onClick={onClick ? handleClick : null}>
                <img
                    alt="img"
                    width={photo.width}
                    height={photo.height}
                    src={`${process.env.GATSBY_S3_BUCKET_BASE_URL}/fit-in/100x100${photo.src}`}
                    className="grid-image"
                />
            </div>
            {handleDelete && <StyledIcon onClick={ev => handleDelete(index)}/>}
        </MenuItemWrapper>
    );
};



const Menu = props => {
    const { handleClick, handleDelete, columns, isActive, padding, type, title, isMobile } = props;
    const { searchClient } = algoliaInfo;
    const [ bShowFilter, setBShowFilter ] = useState(false);
    const [ searchString, setSearchString ] = useState("");
    if(isMobile && !isActive){
        return null
    }
    const menuHits = ({ hits,type, isMobile, searchString }) => {
        const [objects,setObjects]= useState([...hits]);

        useEffect(()=>{
            if(isMobile){
                setObjects([]);
            }
        },[type]);
        useEffect(()=>{
            if(isMobile && !searchString){
                setObjects(prevHits=>{
                    let updatedList = [...prevHits,...hits];
                    const uniqueObjects =_.uniqBy(updatedList, 'objectID');
                    return uniqueObjects;
                });
            } else {
                setObjects(hits);
            }
        },[hits,isMobile,searchString])
        const photos = objects.map(hit => {
            return {
                src: hit.imageUrl,
                width: 4,
                height: 3
            }
        });
        if(photos.length === 0) {
            return <div style={{padding:'20px'}}>There's no {title} images</div>
        }
        return (
            <Gallery
                photos={photos}
                renderImage={props => 
                    <MenuItem 
                        { ...{...props, columns: columns, padding: padding} }
                        baseIndex={0}
                        handleDelete={handleDelete}
                        isMobile={isMobile}
                    />
                }
                //onClick={(ev, {photo, index}) => handleClick(index)}
                // Replaced the handleClick item with the URL string, rather than the index of the array (used previously by Drupal - switched to Algolia)
                onClick={(ev, {photo, index}) => handleClick(`${process.env.GATSBY_S3_BUCKET_BASE_URL}/fit-in/800x800/${photo.src}`)}
            />
        )
    }
    
    const InfiniteHits = connectHits(menuHits);
    const CustomPagination = connectPagination(MyPagination);

    const headerComponent = <Header
                            {...props}
                            keyword={searchString}
                            onChange={setSearchString}
                            bShowFilter={bShowFilter}
                            setBShowFilter={setBShowFilter}
                            isMobile={isMobile}
                            />
    return (
      <Wrapper isMobile={isMobile} className={isActive ? 'visible' : 'non-visible'}>
        <InstantSearch
          indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME_CANVAS_OBJECTS}
          searchClient={searchClient}
        >
          {isMobile && (
            <Title>{title}</Title>
          )}
            <Configure
            hitsPerPage={columns*columns}
            filters={`type:'${type}'`}
            />
           {!isMobile && headerComponent}
      
          
          {isMobile ? (
              <div style={{overflow: 'auto',height: '210px'}}>

                <InfiniteHits type={type} isMobile={isMobile} searchString={searchString} />
                <CustomPagination />
              </div>
          ): (
              <>
                <InfiniteHits isMobile={isMobile} />
                <Pagination defaultRefinement={1} />
              </>
          )}
          {isMobile && headerComponent}
        </InstantSearch>
      </Wrapper>
    );
};

const MyPagination = (props) => {
    const {refine, currentRefinement,nbPages}=props;
    const [isVisible, currentElement] = useVisibility(50);
    useEffect(()=>{
        if(isVisible && nbPages>currentRefinement){
            refine(currentRefinement+1);
        }
    },[isVisible])
    return (nbPages>currentRefinement &&
        <div ref={currentElement}>Loading</div>
    );
  };
Menu.propTypes = {
    columns: PropTypes.number,
    width: PropTypes.string,
    padding: PropTypes.string,
    type: PropTypes.string,
};
Menu.defaultProps = {
    padding: "0px",
    columns: 4,
};

export default Menu;