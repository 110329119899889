import React, { useContext } from 'react';
import styled from '@emotion/styled';
// import core components
import Address from './Address';
import Options from './Options';
import { MultiStepContext } from 'contexts'
import { multiStepConstants } from '../../../../constants';

const { STEP_DELIVERY } = multiStepConstants;

const Wrapper = styled.div`
  display: flex !important;
  justify-content: space-evenly;
  h1 {
    margin-bottom: 15px;
  }
  > * {
    height: fit-content;
    width: calc(50% - 100px);
    padding: 20px;
    box-shadow: ${props => props.theme.shadow.section};
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    flex-direction: column;
    > * {
      width: 100%;
      padding: 0px;
      box-shadow: none;
    }
    .title {
      font-size: 18px;
      line-height: 28px;
    }
  }
`;
const Delivery = props => {
  const {currentStep} = useContext(MultiStepContext); // Context API for multi-step form status
  return (
    <Wrapper className={currentStep !== STEP_DELIVERY ? "hidden-step" : "active-step"}>
      <Address {...props}/>
      <Options
        cardSize={props.cardOptions.field_card_size && props.cardOptions.field_card_size.name}
        country={props.deliveryAddress.country}
        {...props}
      />
    </Wrapper>
  )
};

export default Delivery;
