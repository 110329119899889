import React, { PureComponent, useEffect, useRef, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import $ from 'jquery';
import { useDeviceDetect } from 'hooks';
import _ from 'lodash';

import './styles.css';
import SvgIcon from './Icons/SvgIcon';
import { fontFaces, fontSizes } from '../../../../../../../config/fonts';
import styled from 'styled-components';

import Text from '/static/icons/textToolbar/text.svg';

import TextAlignleft from '/static/icons/alignment/TextAlignleft.svg';
import TextAligncenter from '/static/icons/alignment/TextAligncenter.svg';
import TextAlignright from '/static/icons/alignment/TextAlignright.svg';

import FontSize from '/static/icons/insideToolbar/font-size.svg';
import FontColor from '/static/icons/insideToolbar/font-color.svg';
import FontHighlightColor from '/static/icons/insideToolbar/font-highlight.svg';
import FontFace from '/static/icons/insideToolbar/font-face.svg';
import AlignHorizontal from '/static/icons/insideToolbar/align-horizontal.svg';
import { domHelper } from '../../../../../../helpers/domHelper';

const isBrowser = typeof window !== 'undefined';

let Size = {
  whitelist: [],
};

let Font = {
  whitelist: [],
};
const registerQuill = ReactQuill => {
  const Quill = ReactQuill.Quill;
  Size = Quill.import('attributors/style/size');
  Size.whitelist = fontSizes;
  Quill.register(Size, true);

  Font = Quill.import('formats/font');
  Font.whitelist = fontFaces.map(font => font.replaceAll(' ', '_'));
  Quill.register(Font, true);
};

const initialData = [
  { attributes: { size: '26px', font: 'Indie_Flower' }, insert: '﻿' },
  { attributes: { align: 'center' }, insert: '\n' },
];
export const FormikQuill = props => {
  const { defaultFontFamily, defaultFontSize, content, index } = props;
  const [isMounted, setMounted] = useState(false);
  const isMobile = useDeviceDetect();
  const isInitial = _.isEqual(
    JSON.stringify(initialData),
    JSON.stringify(content[index])
  );
  useEffect(() => {
    setMounted(true);
  }, []);

  /*
  useEffect(() => {
    if (
      !content[index] ||
      (content[index]?.length &&
        content[index].length <= 2 &&
        content[index][0]['insert']?.length <= 1)
    ) {
      setMounted(false);
      setTimeout(() => {
        setMounted(true);
      }, 100);
    }
  }, [defaultFontFamily, defaultFontSize]);
  useEffect(() => {
    if (isInitial) {
      setMounted(false);
      setTimeout(() => {
        setMounted(true);
      }, 100);
    }
  }, [isInitial]);
   */
  if (isMounted) {
    return <FormikQuillComponent {...props} isMobile={isMobile} />;
  } else {
    return null;
  }
};

export class FormikQuillComponent extends PureComponent {
  ReactQuill = null;
  quillRef = React.createRef();
  mainRef = React.createRef();
  quillFormats = [
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'color',
    'background',
    'align',
    'custom',
  ];
  quillModules = {
    toolbar: {
      container: `#toolbar-${this.props.id}`,
      handlers: {
        preview: function(value) {
          const html = this.quill.root.innerHTML;
        },
      },
    },
  };
  state = {
    value: `<p class="ql-align-${this.props.defalutAlignment}"><span class="ql-font-${this.props.defaultFontFamily}" style="font-size: ${this.props.defaultFontSize};"><span class="ql-cursor">&#xFEFF;</span></span></p>`,
    isShowToolbar: false,
    isShowAlignment: false,
    currentAllignment: this.props.align,
    isInitial: true,
  };

  constructor(props) {
    super(props);
    this.outSideClickListener = this.outSideClickListener.bind(this);

    this.ReactQuill = isBrowser ? require('react-quill') : null;
    if (this.ReactQuill) {
      registerQuill(this.ReactQuill);
    }
  }

  componentDidMount() {
    this.handleAlignWidthOrHeightChange();
    document.addEventListener('mousedown', this.outSideClickListener);
    document.addEventListener('touchstart', this.outSideClickListener);

    const fontFamilyElements = document.querySelectorAll(
      '.ql-font > .ql-picker-label'
    );
    for (let i = 0; i < fontFamilyElements.length; i++) {
      fontFamilyElements[i].addEventListener('blur', () => {
        setTimeout(() => {
          this.fontFamilyChangeListener(fontFamilyElements[i]);
        }, 300);
      });
    }

    const fontSizeElements = document.querySelectorAll(
      '.ql-size > .ql-picker-label'
    );
    for (let i = 0; i < fontSizeElements.length; i++) {
      fontSizeElements[i].addEventListener('blur', () => {
        setTimeout(() => {
          this.fontSizeChangeListener(fontSizeElements[i]);
        }, 300);
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      align,
      width,
      height,
      defaultFontFamily,
      defaultFontSize,
    } = prevProps;
    const {
      align: oldAlign,
      width: oldWidth,
      height: oldHeight,
      defaultFontFamily: oldDefaultFontFamily,
      defaultFontSize: oldDefaultFontSize,
    } = this.props;
    /*
    console.log(
      this.props.id,
      'ids',
      defaultFontSize !== oldDefaultFontSize ||
        defaultFontFamily !== oldDefaultFontFamily,
      this.state.isInitial
    );
    */
    if (
      (defaultFontSize !== oldDefaultFontSize ||
        defaultFontFamily !== oldDefaultFontFamily) &&
      this.state.isInitial
    ) {
      //console.log('inside', this.props.id);
      this.setState({
        value: `<p class="ql-align-${this.props.defalutAlignment}"><span class="ql-font-${this.props.defaultFontFamily}" style="font-size: ${this.props.defaultFontSize};"><span class="ql-cursor">&#xFEFF;</span></span></p>`,
      });
    }
    if (align !== oldAlign || width !== oldWidth || height !== oldHeight) {
      this.handleAlignWidthOrHeightChange();
      if (align !== oldAlign) {
        this.setState({
          currentAllignment: this.props.align,
        });
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.outSideClickListener);
    document.removeEventListener('touchstart', this.outSideClickListener);
  }

  outSideClickListener = event => {
    // Do nothing if clicking ref's element or descendent elements
    if (!this.mainRef.current || this.mainRef.current.contains(event.target)) {
      return;
    }
    if (document.activeElement.tagName === 'body') {
      this.setState({
        isShowToolbar: false,
      });
    }
  };

  fontFamilyChangeListener = e => {
    //console.log('e font family change listener');
    const newFontFamily = e?.getAttribute('data-value');
    //console.log(e, newFontFamily, 'here works');
    if (newFontFamily) {
      this.props.changeFontFamily(newFontFamily);
    }
  };

  fontSizeChangeListener = e => {
    const newFontSize = e?.getAttribute('data-value');
    if (newFontSize) {
      this.props.changeFontSize(newFontSize);
    }
  };

  handleAlignWidthOrHeightChange = (newProps = this.props) => {
    const { id, align, width, height, count, index } = newProps;
    const selectString = '#' + id + ' .ql-container.ql-snow';
    $(selectString).css({
      height: `${height}px`,
      width: `${width}px`,
      display: 'flex',
      flexDirection: 'column',
      padding:
        count === 1
          ? '24px'
          : index === 0
          ? '24px 24px 12px 24px'
          : index === 1
          ? '12px 24px 12px 24px'
          : '12px 24px 24px 24px',
    });
    this.clickVerticalAlign(align);
  };

  clickVerticalAlign = p => {
    const { id, index, handleVerticalAlign } = this.props;
    const selectString = '#' + id + ' .ql-editor';
    $(selectString).css({
      justifyContent: p === 'top' ? 'start' : p === 'bottom' ? 'end' : 'center',
    });
    handleVerticalAlign(p, index);
  };

  get CustomToolbar() {
    const { id, isMobile } = this.props;
    const { isShowToolbar, currentAllignment, isShowAlignment } = this.state;
    const wrapperStyle = {};
    if (!isShowToolbar) {
      wrapperStyle.display = 'none';
    }
    if (isMobile) {
      wrapperStyle.top = '-56px';
    }
    const fontStyles = (
      <style>
        {Font.whitelist.map((value, i) => {
          return `.ql-font-${value}{
                  font-family: '${value.replaceAll('_', ' ')}';
                }
                .ql-picker-item[data-value="${value}"]{
                  font-family: '${value.replaceAll('_', ' ')}';
                }
                `;
        })}
        {Size.whitelist.map((value, i) => {
          return `.ql-picker-item[data-value="${value}"]{
                  font-size: 16px;
                }
                `;
        })}
      </style>
    );
    const handleVerticalAlignClick = p => {
      this.setState({
        currentAllignment: p,
        isShowAlignment: false,
      });
      this.clickVerticalAlign(p);
    };

    if (isMobile) {
      return (
        <>
          {fontStyles}
          <MobileToolbar
            id={`toolbar-${id}`}
            isShowToolbar={isShowToolbar}
            clickVerticalAlign={handleVerticalAlignClick}
            isShowAlignment={isShowAlignment}
            changeFontFamily={this.props.changeFontFamily}
            changeFontSize={this.props.changeFontSize}
          />
        </>
      );
    }
    return (
      <div id={`toolbar-${id}`} style={wrapperStyle}>
        <select
          className="ql-font"
          // defaultValue={'Open-Sans'}
          onChange={e => e.persist()}
        >
          {Font.whitelist.map((value, i) => (
            <option key={value} value={value}>
              {value.replaceAll('_', ' ')}
            </option>
          ))}
        </select>

        <select
          className="ql-size"
          defaultValue={'18px'}
          onChange={e => e.persist()}
        >
          {Size.whitelist.map((value, i) => {
            return (
              <option key={value} value={value}>
                {value}
              </option>
            );
          })}
        </select>
        {fontStyles}

        <button className="ql-bold" />
        <button className="ql-italic" />
        <button className="ql-underline" />

        <select className="ql-color"></select>
        <select className="ql-background"></select>
        <select className="ql-align"></select>
        <span
          className={`ql-custom ql-picker ql-icon-picker ${
            isShowAlignment ? 'ql-expanded' : ''
          }`}
        >
          {['top', 'center', 'bottom'].map(
            p =>
              currentAllignment === p && (
                <span key={p} className="ql-picker-label">
                  <SvgIcon
                    position={p}
                    onClick={() => {
                      this.setState(prevState => {
                        return {
                          ...prevState,
                          isShowAlignment: !prevState.isShowAlignment,
                        };
                      });
                    }}
                  ></SvgIcon>
                </span>
              )
          )}
          {isShowAlignment && (
            <span className="ql-picker-options">
              {['top', 'center', 'bottom'].map(p => (
                <span key={p} className="ql-picker-item">
                  <SvgIcon
                    position={p}
                    onClick={e => {
                      this.setState({
                        currentAllignment: p,
                        isShowAlignment: false,
                      });
                      this.clickVerticalAlign(p);
                    }}
                  ></SvgIcon>
                </span>
              ))}
            </span>
          )}
        </span>
      </div>
    );
  }

  render() {
    const {
      id,
      handleType,
      index,
      defalutAlignment = 'left',
      defaultFontFamily,
      defaultFontSize,
      quillRefs,
      isMobile,
    } = this.props;

    return (
      <div
        className={`quill-wrapper`}
        onFocus={ev => {
          this.setState({
            isShowToolbar: true,
          });
        }}
        ref={this.mainRef}
        style={{
          position: 'relative',
        }}
      >
        {this.CustomToolbar}
        <this.ReactQuill
          // ref={this.quillRef}
          ref={element => {
            quillRefs.current[index] = element;
          }}
          style={{ backgroundColor: 'white' }}
          theme="snow"
          onChange={(e, delta, source, editor) => {
            handleType(id, index, editor, quillRefs.current[index]);
            if (editor.getText()?.trim()?.length) {
              //console.log('hereee', editor.getText());
              this.setState({
                isInitial: false,
                value: e,
              });
              return;
            }
            this.setState({
              value: e,
            });
          }}
          modules={this.quillModules}
          formats={this.quillFormats}
          value={this.state.value}
          onFocus={() => {
            this.setState({ isShowToolbar: true });
            // isMobile && document.body.classList.add('disable-scroll');
          }}
          onBlur={() => {
            if (isMobile) {
              this.setState({ isShowToolbar: false });
            }
            // document.body.classList.remove('disable-scroll');
          }}
        />
      </div>
    );
  }
}

const MobileToolbar = ({
  id,
  isShowToolbar,
  clickVerticalAlign,
  changeFontFamily,
  changeFontSize,
}) => {
  const [activeMenu, setActiveMenu] = useState('');
  const toolbarRef = useRef();
  useEffect(() => {
    domHelper.scrollHandler();
    const viewportHandler = () => {
      // var viewport = event.target;
      // const topPosition = viewport.height-60;
      // const toolbarRef = {
      //   current: document.getElementById('toolbar-inside-right-2')
      // }
      toolbarRef.current.style.top =
        (window.visualViewport.offsetTop || window.scrollY) + 'px';
      toolbarRef.current.style.bottom = 'unset';
    };
    window.visualViewport.addEventListener('scroll', viewportHandler);
    window.visualViewport.addEventListener('resize', viewportHandler);
    window.addEventListener('mousedown', e => {
      const tooltip = document.getElementById(id);
      if (tooltip?.contains && tooltip.contains(e.target)) {
        e.preventDefault();
      }
    });
    return () => {
      window.visualViewport.removeEventListener('scroll', viewportHandler);
      window.visualViewport.removeEventListener('resize', viewportHandler);
    };
  }, []);
  useEffect(() => {
    const element = document.querySelector(`#toolbar-${id}`);
    if (isShowToolbar) {
      if (element) {
        element.style.display = 'none';
      }
    } else {
      if (element) {
        element.style.display = '';
      }
    }
    return () => {
      if (element) {
        element.style.display = '';
      }
    };
  }, [isShowToolbar]);

  const colors = [
    'black',
    '#e60000',
    '#ff9900',
    '#ffff00',
    '#008a00',
    '#0066cc',
    '#9933ff',
    '#ffffff',
    '#facccc',
    '#ffebcc',
    '#ffffcc',
    '#cce8cc',
    '#cce0f5',
    '#ebd6ff',
    '#bbbbbb',
    '#f06666',
    '#ffc266',
    '#ffff66',
    '#66b966',
    '#66a3e0',
    '#c285ff',
    '#888888',
    '#a10000',
    '#b26b00',
    '#b2b200',
    '#006100',
    '#0047b2',
    '#6b24b2',
    '#444444',
    '#5c0000',
    '#663d00',
    '#666600',
    '#003700',
    '#002966',
    '#3d1466',
  ];

  return (
    <FixBottomWrapper
      ref={toolbarRef}
      style={{ display: isShowToolbar ? '' : 'none' }}
      id={id}
    >
      <div
        style={{
          display: 'flex',
          height: '50px',
          alignItems: 'center',
          justifyContent: 'space-around',
          borderBottom: '1px solid rgb(235, 235, 235)',
          background: 'white',
        }}
      >
        <div
          style={{ padding: '10px 0px' }}
          className={activeMenu === 'Font' ? 'active' : ''}
          onClick={e => {
            e.preventDefault();
            setActiveMenu('Font');
          }}
        >
          <Text style={{ height: '20px', width: '40px' }} />
        </div>
        <div
          style={{ padding: '10px 0px' }}
          className={activeMenu === 'Size' ? 'active' : ''}
          onClick={e => {
            e.stopPropagation();
            setActiveMenu('Size');
          }}
        >
          <FontSize style={{ height: '20px', width: '40px' }} />
        </div>
        <div
          style={{ padding: '10px 0px' }}
          className={activeMenu === 'Color' ? 'active' : ''}
          onClick={e => {
            e.preventDefault();
            setActiveMenu('Color');
          }}
        >
          <FontColor style={{ height: '20px', width: '40px' }} />
        </div>
        <div
          style={{ padding: '10px 0px' }}
          className={activeMenu === 'BackgroundColor' ? 'active' : ''}
          onClick={e => {
            e.preventDefault();
            setActiveMenu('BackgroundColor');
          }}
        >
          <FontHighlightColor style={{ height: '20px', width: '40px' }} />
        </div>
        <div
          style={{ padding: '10px 0px' }}
          className={activeMenu === 'Face' ? 'active' : ''}
          onClick={e => {
            e.preventDefault();
            setActiveMenu('Face');
          }}
        >
          <FontFace style={{ height: '20px', width: '40px' }} />
        </div>
        <div
          style={{ padding: '10px 0px' }}
          className={activeMenu === 'HorizontalAlign' ? 'active' : ''}
          onClick={e => {
            e.preventDefault();
            setActiveMenu('HorizontalAlign');
          }}
        >
          <AlignHorizontal style={{ height: '20px', width: '40px' }} />
        </div>
        <div
          style={{ padding: '10px 0px' }}
          className={activeMenu === 'VerticalAlign' ? 'active' : ''}
          onClick={e => {
            e.preventDefault();
            setActiveMenu('VerticalAlign');
          }}
        >
          <SvgIcon position={'top'} height="20" width="40"></SvgIcon>
        </div>
      </div>
      <div
        style={{
          display: activeMenu === 'Font' ? 'flex' : 'none',
          flexDirection: 'column',
          alignItems: 'center',
          height: '140px',
          overflow: 'scroll',
          background: 'white',
        }}
      >
        {Font.whitelist.map((value, i) => (
          <button
            style={{
              width: '100%',
              fontFamily: value.replaceAll('_', ' '),
              padding: '0px',
              color: '#000',
            }}
            onClick={() => {
              setActiveMenu('');
              changeFontFamily(value);
            }}
            key={value}
            className="ql-font"
            value={value}
          >
            {value.replaceAll('_', ' ')}
          </button>
        ))}
      </div>

      <div
        style={{
          display: activeMenu === 'Size' ? 'flex' : 'none',
          flexDirection: 'column',
          alignItems: 'center',
          height: '140px',
          overflow: 'scroll',
          background: 'white',
          color: '#000',
        }}
      >
        {Size.whitelist.map((value, i) => {
          return (
            <button
              style={{ width: '100%', padding: '0px', color: '#000' }}
              key={value}
              className="ql-size"
              value={value}
              onClick={() => {
                setActiveMenu('');
                changeFontSize(value);
              }}
            >
              {value}
            </button>
          );
        })}
      </div>

      <div
        style={{
          display: activeMenu === 'Color' ? 'flex' : 'none',
          flexDirection: 'row',
          alignItems: 'center',
          // height:'140px',
          overflow: 'scroll',
          flexWrap: 'wrap',
          background: 'white',
        }}
      >
        {colors.map((color, i) => {
          return (
            <button
              style={{
                width: '24px',
                height: '24px',
                margin: '5px',
                background: color,
                border: 'none',
              }}
              key={color}
              className="ql-color"
              value={color}
              onClick={() => setActiveMenu('')}
            ></button>
          );
        })}
      </div>

      <div
        style={{
          display: activeMenu === 'BackgroundColor' ? 'flex' : 'none',
          flexDirection: 'row',
          alignItems: 'center',
          // height:'140px',
          overflow: 'scroll',
          flexWrap: 'wrap',
          background: 'white',
        }}
      >
        {colors.map((color, i) => {
          return (
            <button
              style={{
                width: '24px',
                height: '24px',
                margin: '5px',
                background: color,
                border: 'none',
              }}
              key={color}
              className="ql-background"
              value={color}
              onClick={() => setActiveMenu('')}
            ></button>
          );
        })}
      </div>
      <div
        style={{
          display: activeMenu === 'Face' ? 'flex' : 'none',
          flexDirection: 'row',
          alignItems: 'center',
          // height:'140px',
          overflow: 'scroll',
          flexWrap: 'wrap',
          justifyContent: 'space-evenly',
          padding: '5px 0px',
          minHeight: '35px',
          background: 'white',
        }}
      >
        <button className="ql-bold" />
        <button className="ql-italic" />
        <button className="ql-underline" />
      </div>
      <div
        style={{
          display: activeMenu === 'HorizontalAlign' ? 'flex' : 'none',
          flexDirection: 'row',
          alignItems: 'center',
          // height:'140px',
          overflow: 'scroll',
          flexWrap: 'wrap',
          justifyContent: 'space-evenly',
          padding: '5px 0px',
          minHeight: '35px',
          background: 'white',
        }}
      >
        <button onClick={() => setActiveMenu('')} className="ql-align" value="">
          <TextAlignleft />
        </button>
        <button
          onClick={() => setActiveMenu('')}
          className="ql-align"
          value="center"
        >
          <TextAligncenter />
        </button>
        <button
          onClick={() => setActiveMenu('')}
          className="ql-align"
          value="right"
        >
          <TextAlignright />
        </button>
      </div>

      <div
        style={{
          display: activeMenu === 'VerticalAlign' ? 'flex' : 'none',
          flexDirection: 'row',
          alignItems: 'center',
          // height:'140px',
          overflow: 'scroll',
          flexWrap: 'wrap',
          justifyContent: 'space-evenly',
          padding: '5px 0px',
          minHeight: '35px',
          background: 'white',
        }}
      >
        {['top', 'center', 'bottom'].map(p => (
          <SvgIcon
            position={p}
            onClick={e => {
              setActiveMenu('');
              clickVerticalAlign(p);
            }}
          ></SvgIcon>
        ))}
      </div>

      <div></div>
    </FixBottomWrapper>
  );
};

const FixBottomWrapper = styled.div`
  position: absolute !important;
  bottom: 100% !important;
  left: 0;
  z-index: 9 !important;
  width: 100% !important;
  background: white !important;
  top: -50px !important;
  .active {
    fill: #5095bf;
    color: #5095bf;
    border-top: 2px solid #5095bf;
  }
`;
