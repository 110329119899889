import React, { useEffect, useState, useRef, useContext } from 'react';
import styled from '@emotion/styled';
import { navigate } from 'gatsby';
// import core components
import {
  Header,
  StepperBar,
  Front,
  Inside,
  Options,
  Delivery,
  Deals,
  Review,
} from 'components/pages/workflow';
import { useDeviceDetect } from 'hooks';
import { Warning } from 'components';
import { Modal } from 'components/Modal';
import { Layout, Container } from 'layouts';
import { MultiStepContext, UserContext } from 'contexts';
import { canvasHelper, multistepHelper } from 'helpers';
import {
  multiStepConstants,
  modalConstants,
  layoutTypeConstants,
} from '../constants';
import withLocation from 'components/hocs/withLocation';
import {
  designsService,
  lineItemsService,
  s3Service,
  usersService,
} from '../services';
import LoadingGif from '/static/gifs/loading.gif';
import { readTextFile } from 'utils/file';
import { createCanvasToImage } from '../components/pages/workflow/inside/utils';
import Loading from '../components/Loading';
import { document } from 'browser-monads';
import { Login } from '../components/Login';
import { Register } from '../components';
import * as fflate from 'fflate';

const slugHelper = require('helpers/gatsbyHelper');

const { STEP_FRONT, STEP_REVIEW } = multiStepConstants;

const StepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .hidden-step {
    visibility: hidden;
    display: none;
    opacity: 0;
    height: 0px;
    * {
      height: 0px !important;
      max-height: 0px !important;
    }
  }
  .active-step {
    transition: ${({ theme }) => theme.transitions.default};
    visibility: visible;
    opacity: 1;
    width: 100%;
    display: block;
    ${({ isMobile }) =>
      isMobile
        ? `
    `
        : `
    min-height: calc(100vh - 90px - 116px);
    `};
  }

  .create-canvas {
    position: absolute;
    top: 2500px;
  }

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    padding: 0px 20px;
  }
`;
const initialAddress = {
  address1: '',
  address2: '',
  locality: '',
  state: '',
  postcode: '',
  country: '',
};
const initialUser = {
  fullName: '',
  relationship: '',
  phone: '',
  company: '',
  email: '',
};

const isJson = (jsonData = '') => {
  try {
    JSON.parse(jsonData);
    return true;
  } catch (error) {
    return false;
  }
};

const Workflow = ({ pageContext, search, path }) => {
  const { design_id: urlDesignId, lineItemId, cartItemId } = search; // fetch step info from the url
  const [design_id, setDesignId] = useState('');
  useEffect(() => {
    setDesignId(urlDesignId);
  }, [urlDesignId]);
  const [currentStep, setCurrentStep] = useState(STEP_FRONT); // Front as the initial state
  const [savedDesign, setSavedDesign] = useState({}); // array of canvases to restore saved version
  const [library, setLibrary] = useState([]); // User library
  const [bLoadingDesign, setBLoadingDesign] = useState(false); // Show loading status of multi-step form
  const [bCartLoading, setBCartLoading] = useState(false); // Show loading status of multi-step form
  const [warnings, setWarnings] = useState([]); // Show warning after click buy
  const frontStepRef = useRef(); // Ref to the front-step canvas manipulations
  const insideStepRef = useRef(); // Ref to the inside-step
  const reviewRef = useRef();

  const [lineItemToUpdate, setLineItemToUpdate] = useState(); // lineItem object while editing it
  const [cardOptions, setCardOptions] = useState({}); // State to manage the options status
  const [deliveryAddress, setDeliveryAddress] = useState(initialAddress); // State to manage the delivery address
  const [deliveryUser, setDeliveryUser] = useState(initialUser); // State to manage the delivery user
  const [deliveryOption, setDeliveryOption] = useState(); // State to manage the delivery option
  const [deliveryDate, setDeliveryDate] = useState(new Date()); // State to manage the delivery date
  const [selectedDeal, setSelectedDeal] = useState({}); // State to manage the deals
  const [loadedOfferId, setLoadedOfferId] = useState(); // Offer id when update a line-item
  const [activeModal, setActiveModal] = useState(modalConstants.HIDDEN);
  const [canvasImgs, setCanvasImgs] = useState([]); // image data-urls of canvases
  const [showLoginModal, setShowLoginModal] = useState(modalConstants.HIDDEN);
  const [loginData, setLoginData] = useState({
    user: {
      email: '',
      password: '',
      authCode: '',
      newPassword: '',
      forgotEmail: '',
    },
    meta: { error: '', pageIndex: 0, bLoading: false },
  });
  const [registerData, setRegisterData] = useState({
    user: { email: '', password: '', authCode: '' },
    meta: { error: '', signupStage: 0, bLoading: false },
  });
  const isMobile = useDeviceDetect();
  const { user } = useContext(UserContext); // Context API to update user state

  const multiStepContext = {
    // organize context for multiple steps
    currentStep,
    setCurrentStep,

    library,
    setLibrary,

    pageContext,

    lineItemToUpdate,
    setLineItemToUpdate,
  };

  useEffect(() => {
    if (design_id && !lineItemId) {
      loadDesign(design_id);
    }
    if (lineItemId) {
      loadLineItem(lineItemId);
    }
  }, [design_id, lineItemId]);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (currentStep === STEP_REVIEW) {
      try {
        const imgs = [
          canvasHelper.getDataURL(frontStepRef),
          ...insideStepRef.current.getPreviewImgs(),
        ];
        setCanvasImgs(imgs);
      } catch (error) {
        ////console.log(error);
        setCanvasImgs([]);
      }
    }
  }, [currentStep]);

  function setInitialDelivery(lineItem) {
    setDeliveryUser(prevState => {
      return {
        ...prevState,
        fullName: lineItem.deliveryName,
        phone: lineItem.deliveryPhoneNumber,
        company: lineItem.deliveryCompany,
      };
    });
    setDeliveryAddress({
      ...deliveryAddress,
      address1: lineItem.deliveryStreetAddress,
      address2: lineItem.deliveryStreetAddress2,
      locality: lineItem.deliveryCity,
      state: lineItem.deliveryState,
      postcode: lineItem.deliveryPostcode,
      country: lineItem.deliveryCountry,
    });
    setLoadedOfferId(lineItem.dealSelection);
  }
  const loadLineItem = async lineItemId => {
    setBLoadingDesign(true);
    try {
      const originalLineItem = await lineItemsService.getLineItem(lineItemId);
      setDesign(originalLineItem);
      setLineItemToUpdate(originalLineItem); // Set the lineItem state
      setInitialDelivery(originalLineItem);
    } catch (error) {
      ////console.log(error);
      redirectWithoutLoading();
    }
    setBLoadingDesign(false);
  };
  // Load design when there is a design id
  const redirectWithoutLoading = () => {
    navigate(
      slugHelper.generateCardSlug(pageContext.category, pageContext.title)
    );
  };
  const loadDesign = async design_id => {
    setBLoadingDesign(true);
    try {
      const design = await designsService.getDesign(design_id);
      setDesign(design[0]);
    } catch (error) {
      ////console.log(error);
      redirectWithoutLoading();
    }
    setBLoadingDesign(false);
  };
  // Load designs from their lineItem
  // Load Lineitem and restore designs and meta-data
  const setDesign = async design => {
    const outlayData = await readTextFile(
      `${process.env.GATSBY_S3_DESIGN_BUCKET_BASE_URL}/${design.fabricJsonFront}`,
      text => loadCanvas('outlay', text)
    );
    const compressed = new Uint8Array(outlayData.split(","));
    const decompressed = fflate.decompressSync(compressed);
    const origText = fflate.strFromU8(decompressed);
    const outlay = JSON.parse(JSON.parse(origText));

    let inlay0 = {};
    if (design.quillJsonInsideL) {
      inlay0 = JSON.parse(design.quillJsonInsideL);
    } else {
      if (isJson(design.fabricJsonInsideL)) {
        try {
          inlay0 = JSON.parse(design.fabricJsonInsideL);
        } catch (error) {
          ////console.log(error);
        }
      } else {
        const inlay0Data = await readTextFile(
          `${process.env.GATSBY_S3_DESIGN_BUCKET_BASE_URL}/${design.fabricJsonInsideL}`,
          text => loadCanvas('inlay0', text)
        );
        try {
          inlay0 = JSON.parse(inlay0Data);
          try {
            inlay0 = JSON.parse(inlay0);
          } catch (error) {
            ////console.log(error);
          }
        } catch (error) {
          ////console.log(error);
        }
      }
    }

    let inlay1 = {};
    if (design.quillJsonInsideR) {
      inlay1 = JSON.parse(design.quillJsonInsideR);
    } else {
      if (isJson(design.fabricJsonInsideR)) {
        try {
          inlay1 = JSON.parse(design.fabricJsonInsideR);
        } catch (error) {
          ////console.log(error);
        }
      } else {
        const inlay1Data = await readTextFile(
          `${process.env.GATSBY_S3_DESIGN_BUCKET_BASE_URL}/${design.fabricJsonInsideR}`,
          text => loadCanvas('inlay1', text)
        );
        try {
          inlay1 = JSON.parse(inlay1Data);
          try {
            inlay1 = JSON.parse(inlay1);
          } catch (error) {
            ////console.log(error);
          }
        } catch (error) {
          ////console.log(error);
        }
      }
    }
    const newDesign = {
      outlay: { json: outlay },
      inlay0: { json: inlay0 },
      inlay1: { json: inlay1 },
    };
    setSavedDesign(newDesign);
    const newLayout = multistepHelper.getLayoutBasedOnJsonDesign(newDesign);
    insideStepRef.current.handleChangeLayout(newLayout);
  };

  const saveDesign = (s3Keys, quillArray) => {
    const thumbnailFrontImg = canvasHelper.getDataURL(frontStepRef);
    if (design_id) {
      const designPayload = {
        fabricJsonFront: s3Keys[0],
        fabricJsonInsideL: quillArray[0] || s3Keys[1],
        fabricJsonInsideR: quillArray[1] || s3Keys[2],
        thumbnailOfFront: thumbnailFrontImg,
      };
      return designsService
        .updateDesignAsync(design_id, designPayload)
        .then(res => {
          return design_id;
          // createNotification('success', "Just saved design")
        });
    } else {
      const designPayload = {
        categoryName: pageContext.category,
        cardTitle: pageContext.title,
        cognitoUserId: user.cognitoUserId,
        fabricJsonFront: s3Keys[0],
        fabricJsonInsideL: quillArray[0] || s3Keys[1],
        fabricJsonInsideR: quillArray[1] || s3Keys[2],
        thumbnailOfFront: "", //thumbnailFrontImg
      };
      return designsService
        .saveDesignAsync(designPayload)
        .then(res => res.json())
        .then(data => {
          return data.id;
          // localstorageHelper.saveDesignId(data.id); lineItemId, cartItemId
          // const params = URLSearchParams();
          // params.set('lineItemId',lineItemId)
          // params.set('cartItemId',cartItemId)
          // params.set('design_id',data.id)
          // window.history.pushState('samePage', 'saveDesign', `?${params.toString()}`);
          // createNotification('success', "Just saved design")
        });
    }
  };

  const handleLogin = ev => {
    setLoginData({ ...loginData, meta: { ...loginData.meta, pageIndex: 0 } });
    setShowLoginModal(modalConstants.LOGIN);
  };

  const handleSaveDesign = async () => {
    if (!user) {
      handleLogin();
      return;
    }
    let itemId = slugHelper.generateUniqueId(pageContext.title);
    const canvasesTextArr = [
      canvasHelper.getJson(frontStepRef),
      ...insideStepRef.current.getLineItemJsons(),
    ];
    const s3Keys = await s3Service.uploadTextArray(canvasesTextArr, itemId);
    const quillArray = insideStepRef.current.getQuillJsons();
    saveDesign(s3Keys, quillArray).then(designId => {
      if (!design_id) {
        // localstorageHelper.saveDesignId(data.id); lineItemId, cartItemId
        const params = new URLSearchParams();
        params.set('lineItemId', lineItemId);
        params.set('cartItemId', cartItemId);
        params.set('design_id', designId);
        window.history.pushState(
          'samePage',
          'saveDesign',
          `?${params.toString()}`
        );
        setDesignId(designId);
      }
    });
  };

  useEffect(()=>{
    // This effect just controlling by handle buy function.
    const handleBuyCart = async () => {
      // generate a unique id for s3 file naming
      let itemId = slugHelper.generateUniqueId(pageContext.title);
      // Running setstates before canvas rendering operations.
      function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }
      await sleep(1)
      let getJsons = await insideStepRef.current.getCanvasJsons();
      let frontJson = canvasHelper.getJson(frontStepRef);
      let frontJsonDataUrl = canvasHelper.getDataURL(frontStepRef);
      const canvasesTextArr = [
        frontJson,
        ...getJsons,
      ];
      const s3Keys = await s3Service.uploadTextArray(canvasesTextArr, itemId);
      const quillArray = insideStepRef.current.getQuillJsons();
      reviewRef.current.handleAddToCart(s3Keys, quillArray,frontJsonDataUrl);
      if (user) {
        saveDesign(s3Keys, quillArray);
      }
    }
    if(bCartLoading) {
      handleBuyCart();
    }
  },[bCartLoading])


  /** Handler when click Buy */
  const handleBuy = async () => {
    if(bCartLoading) return;
    try {
      const ret = multistepHelper.validateData(
        deliveryOption,
        deliveryUser,
        deliveryAddress
      );
      setWarnings(ret);
      if (ret.length > 0) {
        setActiveModal(modalConstants.WARNING);
        setBCartLoading(false);
      } else {
        setBCartLoading(true);
      }
    } catch (error) {
      setBCartLoading(false);
      ////console.log(error);
      // Do not remove below line, page shouldn't be able to scroll until process finish.
      document.getElementsByTagName('html')[0].style.overflow = 'auto';
    }
  };

  const closeModal = () => {
    // Close an opening modal
    setShowLoginModal(modalConstants.HIDDEN);
  };
  const handleConfirm = email => {
    setRegisterData({
      ...registerData,
      user: { ...registerData.user, email },
      meta: { ...registerData.meta, signupStage: 1 },
    });
    setShowLoginModal(modalConstants.REGISTER);
  };

  return (
    <Layout title={pageContext.title} noFooter={isMobile}>
      {bCartLoading && <Loading />}
      <Modal isActive={showLoginModal} hideModal={closeModal}>
        {showLoginModal === modalConstants.LOGIN && (
          <Login
            data={loginData}
            setData={setLoginData}
            onConfirm={handleConfirm}
            onClose={closeModal}
            onRegister={ev => setShowLoginModal(modalConstants.REGISTER)}
          />
        )}
        {showLoginModal === modalConstants.REGISTER && (
          <Register
            data={registerData}
            setData={setRegisterData}
            onClose={closeModal}
            onLogin={handleLogin}
          />
        )}
      </Modal>
      <Container>
        <MultiStepContext.Provider value={multiStepContext}>
          <Header title={pageContext.title} category={pageContext.category} />
          <StepsWrapper isMobile={isMobile}>
            {!bLoadingDesign ? (
              <>
                <Front
                  onSaveDesign={handleSaveDesign}
                  canvasRef={frontStepRef}
                  isMobile={isMobile}
                  savedCanvas={savedDesign.outlay}
                  design_id={design_id}
                  path={path}
                />
                <Inside
                  onSaveDesign={handleSaveDesign}
                  frontCanvas={frontStepRef?.current?.canvas}
                  isMobile={isMobile}
                  ref={insideStepRef}
                  savedCanvas={[savedDesign.inlay0, savedDesign.inlay1]}
                />
                <Options
                  cardOptions={cardOptions}
                  setCardOptions={setCardOptions}
                />
                <Delivery
                  cardOptions={cardOptions}
                  deliveryAddress={deliveryAddress}
                  setDeliveryAddress={setDeliveryAddress}
                  deliveryUser={deliveryUser}
                  setDeliveryUser={setDeliveryUser}
                  deliveryOption={deliveryOption}
                  setDeliveryOption={setDeliveryOption}
                  deliveryDate={deliveryDate}
                  setDeliveryDate={setDeliveryDate}
                  warnings={warnings}
                />
                <Deals
                  selectedDeal={selectedDeal}
                  setSelectedDeal={setSelectedDeal}
                  loadedOfferId={loadedOfferId}
                />
                <Review
                  ref={reviewRef}
                  cardOptions={cardOptions}
                  deliveryAddress={deliveryAddress}
                  deliveryUser={deliveryUser}
                  setDeliveryUser={setDeliveryUser}
                  deliveryOption={deliveryOption}
                  deliveryDate={deliveryDate}
                  selectedDeal={selectedDeal}
                  warnings={warnings}
                  setBCartLoading={setBCartLoading}
                  cartItemId={cartItemId}
                  canvasImgs={canvasImgs}
                  isMobile={isMobile}
                  defaultQuantity={lineItemToUpdate?.quantityCard}
                  setBCardLoading={setBCartLoading}
                />
              </>
            ) : (
              <img
                className="loading-gif large"
                src={LoadingGif}
                alt="image loading"
              />
            )}
          </StepsWrapper>
          {isMobile && (
            <StepperBar
              isMobile={isMobile}
              onBuy={handleBuy}
              warnings={warnings}
              bCartLoading={bCartLoading}
              setBCartLoading={setBCartLoading}
              cartItemId={cartItemId}
            />
          )}
          {!isMobile && (
            <StepperBar
              onBuy={handleBuy}
              warnings={warnings}
              bCartLoading={bCartLoading}
              setBCartLoading={setBCartLoading}
              cartItemId={cartItemId}
            />
          )}
        </MultiStepContext.Provider>
        <Modal
          isActive={activeModal}
          hideModal={() => setActiveModal(modalConstants.HIDDEN)}
        >
          {activeModal === modalConstants.WARNING && (
            <Warning
              title="Those fields are missing"
              warnings={warnings}
              handleClick={() => setActiveModal(modalConstants.HIDDEN)}
            />
          )}
        </Modal>
      </Container>
    </Layout>
  );
};

export default withLocation(Workflow);
