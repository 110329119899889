import React, {useEffect, useState, useContext} from 'react';
import styled from '@emotion/styled';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from "prop-types"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
var clone = require('clone');
// import core components
import { MultiStepContext } from 'contexts';
import { multiStepConstants } from '../../../../constants';
import { ActionCard } from 'components';
import { toAlpha2Code } from 'utils/countries';
import { toShortDate, isBusinessDay, getCurrentHour, getShortToday, getNextBusinessDay } from 'utils/moment';
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
`;
const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 45px;
  &:last-of-type {
    margin-bottom: 0px;
  }
  > div {
    margin-bottom: 15px;
  }
  > div:last-of-type {
    margin-bottom: 0px;
  }
`;
const Title = styled.h1`
    font-weight: bold;
    font-size: 18px;
    line-height: 38px;
`;
const Option = ({cardSize, country, deliveryOption, setDeliveryOption, deliveryDate}) => {
  const { setCurrentStep } = useContext(MultiStepContext);
  const { allNodeMultistepPageDelivery: { edges } } = useStaticQuery(graphql`
    query MyQuery {
      allNodeMultistepPageDelivery {
        edges {
          node {
            drupal_id
            title
            relationships {
              field_singpost {
                drupal_id
                field_enabled
                name
                description {
                value
                }
                field_international_delivery
                field_countries
                field_price {
                formatted
                }
                relationships {
                field_card_size {
                name
                }
                field_image {
                localFile {
                publicURL
                }
                }
                }
                }
                field_uparcel {
                drupal_id
                field_enabled
                name
                description {
                value
                }
                field_international_delivery
                field_price {
                formatted
                }
                relationships {
                field_card_size {
                name
                }
                field_image {
                localFile {
                publicURL
                }
                }
                }
              }
            }
          }
        }
      }
    }`
  );
  const allOptions = [...edges[0].node.relationships.field_singpost, ...edges[0].node.relationships.field_uparcel];
  // filter by card_size
  const [options, setOptions] = useState([]); // All available delivery options
  const clearOptions = () => {
    setOptions([]);
    setDeliveryOption(null);
  }
  const filterOptions = () => {
    // filter by cardSize and do a shallow copy
    const filteredOptionsByCardSize = clone(allOptions.filter(node => node.relationships.field_card_size.map(item => item.name).includes(cardSize)));
    // filter by country code
    const code = country.length>2 ? toAlpha2Code(country): country;
    if(code === 'SG') {
      const internal = filteredOptionsByCardSize.filter(option => option.field_international_delivery === false);
      
      // disable same day delivery option out of the rules
      const sgCurrentHour = getCurrentHour();
      const sgCurrentDate = getShortToday();
      if( !isBusinessDay(deliveryDate) || (toShortDate(deliveryDate) === sgCurrentDate && sgCurrentHour >= 12)) {
        const i = internal.findIndex(option => option.name === "Same Day Delivery");
        if(i >= 0) {
          internal[i].isDisabled = true;
        }
      }

      // set correct delivery date of the same and next day delivery
      let i = internal.findIndex(option => option.name === "Same Day Delivery");
      if(i >= 0) internal[i].deliveryDate = deliveryDate;
      i = internal.findIndex(option => option.name === "Next Day Delivery");
      if(i >= 0) internal[i].deliveryDate = getNextBusinessDay(deliveryDate);
      console.log("deliveryDate: ",deliveryDate);
      console.log("next day: ", getNextBusinessDay(deliveryDate));

      setOptions(internal);
      setDeliveryOption(internal[0] ? internal[0] : null);
    } else {
      const international = filteredOptionsByCardSize.filter(option => option.field_international_delivery === true && option.field_countries.indexOf(code) > -1);
      setOptions(international);
      setDeliveryOption(international[0] ? international[0]: null);
    }
  } 
  useEffect(() => {
    if(country && cardSize) filterOptions();
    else clearOptions();
  }, [country, cardSize, deliveryDate])

  return (
    <Wrapper>
      <Title className="title">Delivery Option</Title>
        <CardWrapper>
          {options.length > 0 && options.map(option =>
            <ActionCard
              key={option.name}
              metaData={option}
              // if the option name is selected, check it
              isSelected={deliveryOption.name == option.name}
              // when an user clicks the option, set that option as selected
              handleCheck={op => setDeliveryOption(op)}
            />)
          }
          {options.length == 0 && !country &&
            <span className="warning">
              <FontAwesomeIcon icon={faExclamationCircle} />
              &nbsp;Please enter an address to see the delivery options available
            </span>
          }
          {options.length == 0 && !cardSize &&
            <span className="warning">
              <FontAwesomeIcon icon={faExclamationCircle} />
              &nbsp;Select a card size
            </span>
          }
          {country && cardSize && options.length == 0 &&
            <span className="warning">
              <FontAwesomeIcon icon={faExclamationCircle} />
              &nbsp;No available options for <a onClick={ev => setCurrentStep(multiStepConstants.STEP_OPTIONS)}>{cardSize}</a>. 
            </span>
          }
        </CardWrapper>
    </Wrapper>
  )
};
Option.propTypes = {
  cardSize: PropTypes.string,
  country: PropTypes.string,
}

export default Option;
