import React, { useState, useRef, useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { Tooltip } from 'react-tooltip'
import { SketchPicker } from 'react-color';
// import core components
import { modalConstants, userActionConstants } from '../../../../constants';
import { useOnClickOutside, useWindowSize } from 'hooks';
// import toolbar icons
import NonGridIcon from '/static/icons/toolbar/non-grid.svg';
import GridIcon from '/static/icons/toolbar/grid.svg';
import TextIcon from '/static/icons/menu/text.svg';
import PaintIcon from '/static/icons/toolbar/fill-drip-light.svg';
import DeleteIcon from '/static/icons/toolbar/delete.svg';
import DeleteAllIcon from '/static/icons/toolbar/delete-all.svg';
import RedoIcon from '/static/icons/toolbar/redo.svg';
import UndoIcon from '/static/icons/toolbar/undo.svg';
import BringFrontIcon from '/static/icons/toolbar/bring-front.svg';
import BringForwardIcon from '/static/icons/toolbar/bring-forward.svg';
import SendBackIcon from '/static/icons/toolbar/send-back.svg';
import SendBackwardIcon from '/static/icons/toolbar/send-backward.svg';
import RotateLeftIcon from '/static/icons/toolbar/rotate.svg';
import RotateRightIcon from '/static/icons/toolbar/rotate.svg';
import SaveIcon from '/static/icons/toolbar/save.svg';
import MessagePen from '/static/icons/menu/pen.svg';
import SvgIcon from '../inside/QuillContainer/FormikQuill/Icons/SvgIcon';
import { Auth } from 'aws-amplify';
import awsConfig from '../../../../aws-exports';
Auth.configure(awsConfig);

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: -81px;
  box-shadow: 1px 1px 6px ${({ theme }) => theme.colors.grey};
  background-color: ${({ theme }) => theme.colors.stroke};
  z-index: 1001;
  border-radius: ${({ theme }) => theme.borderRadius.default};
`;
const Textarea = styled.textarea`
  border: 3px solid #ebebeb;
  border-radius: 10px;
  background: #ffffff;
  height: 180px;
  width: 100%;
  &:focus-visible {
    outline: none;
  }
  &::placeholder {
    color: #262626;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding: 10px;
  }
`;

const SaveButton = styled.div`
  background: #5095bf;
  border-radius: 10px;
  padding: 10px 30px;
  margin: 0px 20px 0px 30px;
  color: white;
  font-weight: 700;
`;
const CancelButton = styled.div`
  color: #262626;
  opacity: 0.5;
`;
const ButtonGroup = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 64px;
  flex-wrap: wrap;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
  &:last-of-type {
    border-bottom: none;
  }
  svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin: 6px;
  }
  .sketch-picker {
    position: absolute;
    right: 24px;
    top: 0px;
    z-index: 1000;
  }
`;
const ToolBarButton = ({ title, icon, handleClick, children }) => {
  const Icon = icon;
  return (
    <>
      {Icon && (
        <>
          <a data-tip data-for={'toolbar-button-' + title}>
            <Icon onClick={handleClick} />
          </a>
          <Tooltip
            id={'toolbar-button-' + title}
            place="right"
            effect="solid"
            className="toolbar-tooltip"
          >
            <span>{title}</span>
          </Tooltip>
        </>
      )}
      {children}
    </>
  );
};
const ToolBar = ({
  onClick,
  toolbar,
  isMobile,
  oldText = '',
  onTextEditSelect,
  isAnyMenuActive,
  setActiveMenu,
  onSaveDesign,
}) => {
  const [isLogged, setIsLogged] = useState(false);
  const [color, setColor] = useState(); // paint color
  const [showSketchPicker, setShowSketchPicker] = useState(false); // state to show or hide sketch picker modal
  const [showGrid, setShowGrid] = useState(false); // state to show or hide grid lines
  const sketchPickerRef = useRef(null); // Ref to detect outside click
  useOnClickOutside(sketchPickerRef, () => setShowSketchPicker(false));
  const handleColorClick = ev => {
    setShowSketchPicker(!showSketchPicker);
  };
  const [isAddText, setIsAddText] = useState(false);
  const [textValue, setTextValue] = useState('');
  const [_width, windowHeight] = useWindowSize();
  useEffect(() => {
    if (oldText) {
      setTextValue(oldText);
      setIsAddText(true);
    }
  }, [oldText]);

  useMemo(() => {
    async function checkLogin() {
      try {
        let authentication = await Auth.currentAuthenticatedUser();
        setIsLogged(authentication);
      } catch (e) {
        //console.error(e); //ignore the console writes
      }
    }
    checkLogin();
  }, []);

  useEffect(() => {
    if (isAddText) {
      document
        .getElementsByTagName('html')[0]
        .classList.add('no-scroll-add-text');
    } else {
      document
        .getElementsByTagName('html')[0]
        .classList.remove('no-scroll-add-text');
    }
    return () => {
      document
        .getElementsByTagName('html')[0]
        .classList.remove('no-scroll-add-text');
    };
  }, [isAddText]);

  if (
    isMobile &&
    toolbar.includes('Operations') &&
    (isAnyMenuActive || isAddText)
  ) {
    if (isAddText) {
      return (
        <div
          style={{
            height: windowHeight,
            width: '100%',
            background: 'white',
            position: 'fixed',
            bottom: 0,
            left: 0,
            zIndex: 99,
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ padding: '30px 20px 20px' }}>
              <Textarea
                placeholder="Add text..."
                rows="3"
                value={textValue}
                onChange={e => setTextValue(e.target.value)}
              ></Textarea>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <CancelButton
                onClick={() => {
                  setIsAddText(false);
                  onTextEditSelect('');
                }}
              >
                Cancel
              </CancelButton>
              <SaveButton
                onClick={() => {
                  if (textValue) {
                    if (oldText) {
                      onClick({
                        action: userActionConstants.EDIT_TEXT,
                        text: textValue,
                      });
                    } else {
                      onClick({
                        action: userActionConstants.ADD_TEXT,
                        text: textValue,
                      });
                    }
                    setIsAddText(false);
                    setTextValue('');
                  }
                }}
              >
                Done
              </SaveButton>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div
        style={{ flex: 1, textAlign: 'center', paddingTop: '10px' }}
        onClick={() => {
          setIsAddText(true);
          setActiveMenu(modalConstants.OTHER);
        }}
      >
        <div>
          <MessagePen style={{ height: '20px', width: '40px' }} />
        </div>
      </div>
    );
  }
  return (
    <Wrapper>
      <ButtonGroup>
        {toolbar.includes('Operations') && (
          <>
            {showGrid && (
              <ToolBarButton
                icon={NonGridIcon}
                title="Hide Grid"
                handleClick={() => {
                  setShowGrid(false);
                  onClick({
                    action: userActionConstants.SHOW_GRID,
                    data: false,
                  });
                }}
              />
            )}
            {!showGrid && (
              <ToolBarButton
                icon={GridIcon}
                title="Show Grid"
                handleClick={() => {
                  setShowGrid(true);
                  onClick({
                    action: userActionConstants.SHOW_GRID,
                    data: true,
                  });
                }}
              />
            )}
            <ToolBarButton
              icon={DeleteIcon}
              title="Delete Object"
              handleClick={() => {
                onClick({ action: userActionConstants.DELETE });
              }}
            />
          </>
        )}
        {toolbar.includes('Text') && (
          <ToolBarButton
            icon={TextIcon}
            title="Add Text"
            handleClick={() => {
              onClick({ action: userActionConstants.ADD_TEXT });
            }}
          />
        )}
        {toolbar.includes('Scenes') && (
          <>
            <ToolBarButton
              icon={PaintIcon}
              title="Background Color"
              handleClick={handleColorClick}
            />
            <div className="sketch-wrapper" ref={sketchPickerRef}>
              {showSketchPicker && (
                <SketchPicker
                  className="sketch-picker"
                  color={color}
                  onChangeComplete={color => {
                    setColor(color);
                    onClick({
                      action: userActionConstants.PICK_COLOR,
                      data: color,
                    });
                  }}
                />
              )}
            </div>
          </>
        )}
      </ButtonGroup>
      {toolbar.includes('Operations') && (
        <>
          <ButtonGroup>
            <ToolBarButton
              icon={UndoIcon}
              title="Undo"
              handleClick={() => {
                onClick({ action: userActionConstants.UNDO });
              }}
            />
            <ToolBarButton
              icon={RedoIcon}
              title="Redo"
              handleClick={() => {
                onClick({ action: userActionConstants.REDO });
              }}
            />
          </ButtonGroup>
          <ButtonGroup>
            <ToolBarButton
              icon={RotateLeftIcon}
              title="Rotate Left"
              handleClick={() => {
                onClick({ action: userActionConstants.ROTATE_LEFT });
              }}
            />
            <ToolBarButton
              icon={RotateRightIcon}
              title="Rotate Right"
              handleClick={() => {
                onClick({ action: userActionConstants.ROTATE_RIGHT });
              }}
            />
          </ButtonGroup>
          <ButtonGroup>
            <ToolBarButton
              icon={BringFrontIcon}
              title="Bring Front"
              handleClick={() => {
                onClick({
                  action: userActionConstants.SELECT_LAYER,
                  data: userActionConstants.BRING_FRONT,
                });
              }}
            />
            <ToolBarButton
              icon={BringForwardIcon}
              title="Bring Forward"
              handleClick={() => {
                onClick({
                  action: userActionConstants.SELECT_LAYER,
                  data: userActionConstants.BRING_FORWARD,
                });
              }}
            />
            <ToolBarButton
              icon={SendBackIcon}
              title="Send Back"
              handleClick={() => {
                onClick({
                  action: userActionConstants.SELECT_LAYER,
                  data: userActionConstants.SEND_BACK,
                });
              }}
            />
            <ToolBarButton
              icon={SendBackwardIcon}
              title="Send Backward"
              handleClick={() => {
                onClick({
                  action: userActionConstants.SELECT_LAYER,
                  data: userActionConstants.SEND_BACKWARD,
                });
              }}
            />
          </ButtonGroup>
          <ButtonGroup>
            <ToolBarButton
              icon={DeleteAllIcon}
              title="Delete All"
              handleClick={() => {
                onClick({ action: userActionConstants.DELETE_ALL });
              }}
            />
            <ToolBarButton
              icon={SaveIcon}
              title="Save Design"
              handleClick={() => {
                onSaveDesign();
              }}
            />
          </ButtonGroup>
        </>
      )}
    </Wrapper>
  );
};

export default ToolBar;
