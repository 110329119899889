import React, {useContext, useEffect} from 'react';
import styled from '@emotion/styled';
import { useStaticQuery, graphql } from 'gatsby';
// import core components
import { ActionCard } from 'components';
import { MultiStepContext } from 'contexts'

import { multiStepConstants } from '../../../../constants';

const { STEP_OPTIONS } = multiStepConstants;

const Wrapper = styled.div`
`;
const Select = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  &:last-of-type {
    margin-bottom: 0px;
  }
`;
const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  > div {
    width: calc(50% - 35px);
    margin-right: 70px;
    margin-bottom: 10px;
    &:nth-of-type(2n) {
      margin-right: 0px;
    }
    &:nth-last-of-type(-n+2) {
      margin-bottom: 0px;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    flex-direction: column;
    > div {
      width: 100%;
      margin: 0px;
      margin-bottom: 15px;

      &:nth-last-of-type(-n+2) {
        margin-bottom: 15px;
      }
    }
  }
`;
const Title = styled.h1`
    font-weight: bold;
    font-size: 18px;
    line-height: 38px;
    margin-bottom: 0px;

    @media (max-width: ${props => props.theme.breakpoints.s}) {
      font-size: 18px;
      line-height: 28px;
    }
`;
const Options = ({ cardOptions, setCardOptions }) => {
  const { currentStep, lineItemToUpdate } = useContext(MultiStepContext); // Context API for multi-step form status
  const { allNodeMultistepPageOptions: { edges } } = useStaticQuery(graphql`
    query MyOptionsQuery {
      allNodeMultistepPageOptions {
        edges {
          node {
            drupal_id
            title
            field_card_size_title
            field_envelope_type_title
            field_envelope_colour_title
            field_gifts_flowers_title
            field_gifts_chocolates_title
            relationships {
              field_card_size {
                field_enabled
                name
                weight
                status
                description {
                  value
                }
                field_price {
                  formatted
                }
                relationships {
                  field_image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          placeholder: BLURRED
                          formats: [WEBP]
                          layout: CONSTRAINED
                        )
                      }
                    }
                  }
                }
              }
              field_envelope_type {
                field_enabled
                name
                weight
                status
                description {
                  value
                }
                relationships {
                  field_image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          placeholder: BLURRED
                          formats: [WEBP]
                          layout: CONSTRAINED
                        )
                      }
                    }
                  }
                }
              }
              field_envelope_colour {
                field_enabled
                name
                weight
                status
                relationships {
                  field_image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          placeholder: BLURRED
                          formats: [WEBP]
                          layout: CONSTRAINED
                        )
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }`
  );
  const { node } = edges[0];
  const options = node.relationships;

  // set tup initial card options
  function getModified(key) {
    switch(key) {
      case 'field_card_size':
        return 'optionCardSize';
      case 'field_envelope_type':
        return 'optionEnvelopeType';
      case 'field_envelope_colour':
        return 'optionEnvelopeColour';
      default:
        return 'unkonwn';
    }
  }
  function getInitialOptions(lineItem) {
    // Sort by weight and set the default option
    const initialOptions = {};
    Object.keys(options).map(key => {
      options[key].sort( (a, b) => {
        return a.weight - b.weight; // Sort by Ascending
      })
      // Select the first item by default when there's no line-item
      if(!lineItem) initialOptions[key] = options[key][0];
      else {
        const obj = options[key].find(op => op.name == lineItem[getModified(key)]);
        if(obj) initialOptions[key] = obj;
        else initialOptions[key] = options[key][0];
      }
    })
    return initialOptions;
  }
  useEffect(() => {
    setCardOptions(getInitialOptions(lineItemToUpdate));  // State to manage the options status, initialized with default options
  }, [lineItemToUpdate])

  return (
    <Wrapper className={currentStep !== STEP_OPTIONS ? "hidden-step" : "active-step"}>
      {Object.keys(options).map(key => <Select key={key}>
          <Title>{node[`${key}_title`]}</Title>
          <CardWrapper>
            {options[key].map(option =>
              <ActionCard
                key={option.name}
                metaData={option}
                // if the option name is selected, check it
                isSelected={cardOptions[key] && cardOptions[key].name == option.name}
                // when an user clicks the option, set that option as selected
                handleCheck={op => {
                  setCardOptions({...cardOptions, [key]: op});
                }}
              />)
            }
          </CardWrapper>
        </Select>
      )}
    </Wrapper>
  )
};

export default Options;
