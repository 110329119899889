import React, { useEffect, useRef, useState } from 'react';
import { toBase64 } from '../../utils/file';
import CloseIcon from '../../../static/icons/controls/close.svg';
import CellPhoneIcon from '../../../static/icons/toolbar/cell-phone.svg';
import { modalConstants } from '../../constants';
import UploadIcon from '../../../static/icons/toolbar/camera.svg';
import styled from '@emotion/styled';
import CropIcon from '../../../static/icons/toolbar/Crop.svg';
import RotateIcon from '../../../static/icons/toolbar/rotation.svg';
import MaximizeIcon from '../../../static/icons/textToolbar/maximize.svg';
import DeleteIcon from '../../../static/icons/toolbar/delete.svg';
import { RangeComponent } from '../pages/workflow/canvas/TextToolBar';

const ItemDiv = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-top: 2px solid transparent;
  &.active {
    fill: #5095bf;
    border-top: 2px solid #5095bf;
    path {
      fill: #5095bf;
    }
    color: #5095bf;
  }
  > div {
    display: none;
  }
`;

const FindYourDeviceWrapper = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  padding: 10px 30px;
  background: #5095bf;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AddPhotoWrapper = styled.div`
  color: #262626;
`;

const OverlayComponent = styled.div`
position: fixed;
top:0;
left:0;
width:100%;
height:100%;
background: rgba(0, 0, 0, 0.5);
z-index ${props => props.zIndex || 1};
`;

const ImageConfigComponent = ({
  activeObject,
  onRotate,
  onZoom,
  onCrop,
  onClose,
}) => {
  const [zoomLevel, setZoomLevel] = useState(
    parseInt(activeObject.scaleX * 100)
  );
  const [cropPercentage, setCropPercentage] = useState(
    parseInt((activeObject.width * 100) / activeObject._originalElement.width)
  );
  const [angle, setAngle] = useState(activeObject.angle);
  const [activeTab, setActiveTab] = useState('Crop');
  const handleDeleteObject = () => {
    activeObject.canvas.remove(activeObject);
    onClose();
  };
  return (
    <div
      style={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        background: 'white',
        zIndex: 10,
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          borderBottom: '1px solid rgb(235, 235, 235)',
        }}
      >
        <ItemDiv
          onClick={() => setActiveTab('Crop')}
          className={activeTab === 'Crop' ? 'active' : ''}
        >
          <CropIcon />
          <div>Crop</div>
        </ItemDiv>
        <ItemDiv
          onClick={() => setActiveTab('Rotate')}
          className={activeTab === 'Rotate' ? 'active' : ''}
        >
          <RotateIcon />
          <div>Rotate</div>
        </ItemDiv>
        <ItemDiv
          onClick={() => setActiveTab('Zoom')}
          className={activeTab === 'Zoom' ? 'active' : ''}
        >
          <MaximizeIcon />
          <div>Zoom</div>
        </ItemDiv>
        <ItemDiv onClick={handleDeleteObject}>
          <DeleteIcon style={{ height: '20px', width: '20px' }} />
          <div>Delete</div>
        </ItemDiv>
      </div>
      <div style={{ padding: '20px' }}>
        {activeTab === 'Rotate' && (
          <RangeComponent
            style={{ margin: '25px 5px 0px 5px' }}
            postfix=""
            sizeValue={angle}
            setSizeValue={angle => {
              onRotate(angle);
              setAngle(angle);
            }}
            MIN={0}
            MAX={360}
          />
        )}
        {activeTab === 'Crop' && (
          <RangeComponent
            style={{ margin: '25px 5px 0px 5px' }}
            postfix=""
            sizeValue={cropPercentage}
            setSizeValue={newCropPercentage => {
              onCrop(newCropPercentage);
              setCropPercentage(newCropPercentage);
            }}
            MIN={0}
            MAX={100}
          />
        )}
        {activeTab === 'Zoom' && (
          <RangeComponent
            style={{ margin: '25px 5px 0px 5px' }}
            postfix=""
            sizeValue={zoomLevel}
            setSizeValue={sizeVal => {
              onZoom(sizeVal / 100);
              setZoomLevel(sizeVal);
            }}
            MIN={25}
            MAX={400}
          />
        )}
        <div>{activeTab}</div>
      </div>
    </div>
  );
};

const MobilePhotoUpload = props => {
  const {
    isAnyMenuActive,
    setActiveMenu,
    onSelect,
    canvasRef,
    onRotate,
    onZoom,
    onCrop,
    styles,
  } = props;
  const activeObject = canvasRef.current?.canvas?.getActiveObject();
  let imageActive;
  if (activeObject) {
    imageActive = !!activeObject.url;
  }
  const [isImageSelected, setImageSelected] = useState(imageActive);
  useEffect(() => {
    const selectionHandle = e => {
      if (e.selected[0]?._originalElement?.src) {
        setImageSelected(true);
      } else {
        setImageSelected(false);
      }
    };
    canvasRef.current.canvas.on('selection:created', selectionHandle);
    canvasRef.current.canvas.on('selection:updated', selectionHandle);
    canvasRef.current.canvas.on('selection:cleared', e => {
      setImageSelected(false);
    });
  }, []);
  const [isAddPhoto, setIsAddPhoto] = useState(false);
  const fileUploadRef = useRef(null);
  const handleFileSelection = e => {
    toBase64(e.target.files[0]).then(base64Image => {
      onSelect(base64Image);
    });
    setIsAddPhoto();
  };
  if (isImageSelected) {
    return (
      <ImageConfigComponent
        activeObject={activeObject}
        onClose={() => {
          setImageSelected(false);
          canvasRef.current.canvas.discardActiveObject().renderAll();
        }}
        onZoom={onZoom}
        onCrop={onCrop}
        onRotate={onRotate}
      />
    );
  }
  if (!isAnyMenuActive && !isAddPhoto) {
    return null;
  }
  let addPhotoComponent;
  if (isAddPhoto) {
    addPhotoComponent = (
      <>
        <div
          style={{
            position: 'fixed',
            bottom: 0,
            width: '100%',
            left: 0,
            background: 'white',
            zIndex: -1,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '10px 20px',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h4>Upload Photo</h4>
              <div onClick={() => setIsAddPhoto()}>
                <CloseIcon />
              </div>
            </div>
            <input
              onChange={handleFileSelection}
              ref={fileUploadRef}
              accept="image/gif,image/jpeg,image/jpg,image/png"
              type="file"
              className="hidden"
            />
            <div
              onClick={() => {
                fileUploadRef.current.click();
              }}
              style={{
                display: 'flex',
                flexDirection: 'row',
                borderRadius: '8px',
                overflow: 'hidden',
                margin: '20px 0px',
              }}
            >
              <div style={{ background: '#C8E3F9' }}>
                <CellPhoneIcon fill="white" />
              </div>
              <FindYourDeviceWrapper>FROM YOUR DEVICE</FindYourDeviceWrapper>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {addPhotoComponent}
      <div
        style={styles}
        onClick={() => {
          setIsAddPhoto(true);
          canvasRef.current.canvas.discardActiveObject().renderAll();
          setActiveMenu(modalConstants.HIDDEN);
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <AddPhotoWrapper>
            <UploadIcon style={{ width: '24px', height: '24px' }} />
          </AddPhotoWrapper>
        </div>
      </div>
    </>
  );
};

export default MobilePhotoUpload;
