import React, {
  forwardRef,
  Fragment,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { FormikQuill } from './FormikQuill';

import './styles.css';
import './FormikQuill';
import styled from 'styled-components';
import LeftArrowIcon from '/static/icons/arrows/arrow-left.svg';
import { canvasHelper } from '../../../../../helpers/canvasHelper';

const TopBorder = styled.div`
  z-index: 1;
  display: flex;
  left: calc(50% - 28px);
  position: absolute;
  top: -11px;
  justify-content: center;
  transform: rotate(90deg);
`;
const BottomBorder = styled.div`
  z-index: 1;
  display: flex;
  left: calc(50% - 28px);
  position: absolute;
  bottom: -11px;
  justify-content: center;
  transform: rotate(-90deg);
`;
const LeftBorder = styled.div`
  z-index: 1;
  display: flex;
  top: 50%;
  position: absolute;
  left: -16px;
  justify-content: center;
  flex-direction: column;
  transform: rotate(0deg);
`;
const RightBorder = styled.div`
  z-index: 1;
  display: flex;
  top: 50%;
  position: absolute;
  right: -16px;
  justify-content: center;
  flex-direction: column;
  transform: rotate(180deg);
`;

const BorderText = styled.div`
  background: white;
  padding: 0px 10px;
`;

const QuillContainer = ({
  ref,
  vAlign,
  setVAlign,
  content,
  setContent,
  config,
  isMobile,
  isActive,
  canSwip,
  swipEvents,
  jsonData,
  defaultFontSize,
  defaultFontFamily,
  onFontChange,
  onFontSizeChange,
  orientation,
}) => {
  const quillRefs = useRef([]);
  const { containerId, width, height, count } = config;
  useEffect(() => {
    setVAlign(
      Array.from(Array(count).keys()).map(i =>
        containerId.includes('left') ? 'top' : 'center'
      )
    );
    setContent(Array.from(Array(count).keys()).map(i => {}));
  }, [count]);

  useEffect(() => {
    if (jsonData && !canvasHelper.isCanvasJson(jsonData.json)) {
      quillRefs.current.map((quillRef, index) => {
        quillRef?.getEditor()?.setContents(jsonData.json.content[index]);
      });
    }
  }, [jsonData, quillRefs?.current]);

  const changeFontFamily = fontFamily => {
    if (fontFamily !== defaultFontSize) {
      onFontChange(fontFamily);
    }
  };

  const changeFontSize = fontSize => {
    if (fontSize !== defaultFontSize) {
      onFontSizeChange(fontSize);
    }
  };

  const onQuillChange = (id, index, editor, ref) => {
    let boundingRect = null;
    try {
      boundingRect = editor.getBounds(0, editor.getLength());
    } catch (e) {}
    // adding padding counts
    let paddingTop = count === 1 || index === 0 ? 24 : 12;
    let paddingBottom = count === 1 || index === 2 ? 24 : 12;
    if (
      !boundingRect ||
      boundingRect.height >
        Math.floor(height / count) - paddingTop - paddingBottom
    ) {
      ref.getEditor().setContents(content[index]);
    } else {
      /*
      let lastOps = ref?.lastDeltaChangeSet?.ops;
      if (
        lastOps?.length &&
        lastOps[lastOps.length - 1]?.attributes?.size &&
        lastOps[lastOps.length - 1]?.attributes?.size !== defaultFontSize
      ) {
        onFontSizeChange(lastOps[lastOps.length - 1]?.attributes?.size);
      }
      if (
        lastOps?.length &&
        lastOps[lastOps.length - 1]?.attributes?.font &&
        lastOps[lastOps.length - 1]?.attributes?.font !== defaultFontFamily
      ) {
        onFontChange(lastOps[lastOps.length - 1]?.attributes?.font);
      }
       */
      const newContent = content;
      newContent[index] = editor.getContents().ops;
      setContent(newContent);
    }
  };

  const onVerticalAlign = (type, index) => {
    const newAlign = vAlign;
    newAlign[index] = type;
    setVAlign(newAlign);
  };

  let style = {};
  if (isMobile && !isActive) {
    style = { display: 'none' };
  }

  let defalutAlignment = 'left';
  return (
    <div
      className="quill_container"
      style={{
        position: 'relative',
        margin: '5px',
        transform: `scale(${
          isMobile ? (orientation === 'Landscape' ? 0.6 : 0.7) : 1
        })`,
        ...style,
      }}
      id={containerId}
    >
      <BorderComponent
        isMobile={isMobile}
        canSwip={canSwip}
        {...swipEvents}
        style={style}
      />
      {Array.from(Array(count).keys()).map(index => {
        const id = `${containerId}-${index}`;
        if (containerId.includes('right')) {
          defalutAlignment = 'center';
        }
        return (
          <Fragment key={id}>
            <div key={id} id={id} style={style}>
              <FormikQuill
                content={content}
                defaultFontSize={defaultFontSize}
                defaultFontFamily={defaultFontFamily}
                isMobile={isMobile}
                id={id}
                index={index}
                align={vAlign[index]}
                handleType={onQuillChange}
                changeFontFamily={changeFontFamily}
                changeFontSize={changeFontSize}
                handleVerticalAlign={onVerticalAlign}
                width={width}
                height={height / count}
                count={count}
                defalutAlignment={defalutAlignment}
                quillRefs={quillRefs}
              />
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};

export default QuillContainer;

export const BorderComponent = ({
  isMobile,
  canSwip,
  onDownSwipe,
  onUpSwipe,
  onRightSwipe,
  onLeftSwipe,
}) => {
  return (
    <>
      {isMobile && canSwip.canSwipUp && (
        <>
          <TopBorder onClick={onUpSwipe} id={'quill-top-border'}>
            <BorderText>
              <LeftArrowIcon />
            </BorderText>
          </TopBorder>
          <BottomBorder
            style={{ transform: 'rotate(0deg)' }}
            id={'quill-border-bottom'}
          >
            <BorderText>Bottom</BorderText>
          </BottomBorder>
        </>
      )}
      {isMobile && canSwip.canSwipDown && (
        <>
          <BottomBorder onClick={onDownSwipe} id={'quill-border-bottom'}>
            <BorderText rightLeft>
              <LeftArrowIcon />
            </BorderText>
          </BottomBorder>
          <TopBorder
            style={{ transform: 'rotate(0deg)' }}
            id={'quill-top-border'}
          >
            <BorderText>Top</BorderText>
          </TopBorder>
        </>
      )}
      {isMobile && canSwip.canSwipRight && (
        <>
          <RightBorder onClick={onRightSwipe} id={'quill-right-border'}>
            <BorderText rightLeft>
              <LeftArrowIcon />
            </BorderText>
          </RightBorder>
          <LeftBorder
            style={{ transform: 'rotate(-90deg)', left: '-24px' }}
            id={'quill-left-border'}
          >
          <BorderText>Left</BorderText>
          </LeftBorder>
        </>
      )}
      {isMobile && canSwip.canSwipLeft && (
        <>
          <LeftBorder onClick={onLeftSwipe} id={'quill-left-border'}>
            <BorderText rightLeft>
              <LeftArrowIcon />
            </BorderText>
          </LeftBorder>
          <RightBorder
            style={{ transform: 'rotate(90deg)', right: '-28px' }}
            id={'quill-right-border'}
          >
            <BorderText>Right</BorderText>
          </RightBorder>
        </>
      )}
    </>
  );
};
