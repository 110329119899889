import React from 'react';
import styled from '@emotion/styled';
import orderBy from 'lodash.orderby';
import { ClearRefinements } from 'react-instantsearch-dom';

// import core components
import { RefinementList, RefinementMenu, StateResults }  from '../../../../algolia';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  > .filter-body {
    display: flex;
    flex-direction: column;
  }
  > .filter-bottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;
    label {
      ${({theme}) => theme.colors.black};
    }
  }
  @media (max-width: ${({theme}) => theme.breakpoints.s}) {
    .filter-body {
      visibility: ${({bShow}) => `${bShow ? 'visible' : 'hidden'}`};
      ${({bShow}) => !bShow && 'height: 0px;'}
    }
  }
`;
const MenuWrapper = styled.div`
  display: flex;
  > div {
    margin-right : 15px;
    &:last-of-type {
      margin-right: 0px;
    }
  }
  @media (max-width: ${({theme}) => theme.breakpoints.s}) {
    flex-direction: column;
    padding: 0px 20px;
    > div {
      margin: 0px;
      margin-bottom: 10px;
      &:last-of-type {
        margin-bottom: 0px;
      }
    }
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
  margin-left: -15px;
  > div {
    button {
      margin-left: 15px;
    }
  }
  @media (max-width: ${({theme}) => theme.breakpoints.s}) {
    margin: 0px;
    padding: 0px 20px;
    flex-direction: column;
    > div {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      button {
        margin: 0px;
        margin-bottom: 15px;
        width: calc(50% - 5px);
      }
    }
  }
`
const StyledClearRefinements = styled(ClearRefinements)`
  > button {
    background-color: white;
    color: ${({theme}) => theme.colors.blue};
    font-size: 14px;
    &:hover {
      background-color: white;
      text-decoration: underline;
    }
  }
`;
const Filter = ({ bShowFilter, setBShowFilter }) => {
  return (
    <Wrapper bShow={bShowFilter}>
      <div className="filter-body">
        <ButtonWrapper>
            <RefinementList
              attribute="relationships.field_orientation.name"
              transformItems={items =>
                orderBy(items, ['label', 'count'], ['asc', 'desc'])
              }
            />
            <RefinementList
              attribute="relationships.field_filters.name"
              transformItems={items =>
                orderBy(items, ['label', 'count'], ['asc', 'desc'])
              }
            />
        </ButtonWrapper>
        <MenuWrapper>
          <RefinementMenu
            attribute="relationships.field_recipient.name"
            text="Who's It For?"
            transformItems={items =>
              orderBy(items, ['label', 'count'], ['asc', 'desc'])
            }
          />
          <RefinementMenu
            attribute="relationships.field_styles.name"
            text="Style"
            transformItems={items =>
              orderBy(items, ['label', 'count'], ['asc', 'desc'])
            }
          />
          <RefinementMenu
            attribute="relationships.field_brand.name"
            text="Brand"
            transformItems={items =>
              orderBy(items, ['label', 'count'], ['asc', 'desc'])
            }
          />
          <RefinementMenu
            attribute="relationships.field_age.name"
            text="Age"
            transformItems={items =>
              orderBy(items, ['label', 'count'], ['asc', 'desc'])
            }
          />
        </MenuWrapper>
      </div>
      <div className="filter-bottom">
        <StateResults />
        <StyledClearRefinements />
      </div>
    </Wrapper>
  )
}
export default Filter;