import React from 'react';
import LoadingGif from '../../../static/gifs/loading.gif';

function Loading(props) {
  return (
    <div
      style={{
        height: window.innerWidth < 768 ? '200vh' : '100%',
        width: '100%',
        background: 'rgba(0,0,0,0.4)',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 999999,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img
        className="loading-gif medium"
        src={LoadingGif}
        alt="image loading"
      />
    </div>
  );
}

export default Loading;
