import React, { useState, useContext, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { Tooltip } from 'react-tooltip'
import PropTypes from 'prop-types';
// import core components
import Menu from '../Menu';
import Library from '../Library';
import { modalConstants, userActionConstants } from '../../../../constants';
import { queryHelper } from 'helpers';
import { MultiStepContext, MenuBarContext } from 'contexts';
import { useOnClickOutside, useSwipeDetector } from 'hooks';
import { debounce, isEmpty } from 'lodash';

// import menubar icons
import ArtIcon from '/static/icons/menu/art.svg';
import BackgroundIcon from '/static/icons/menu/background.svg';
import EmojiIcon from '/static/icons/menu/emoji.svg';
import StickyIcon from '/static/icons/menu/sticky-note-solid.svg';
import UploadIcon from '/static/icons/toolbar/camera.svg';
import ToolBar from './ToolBar';
import MobilePhotoUpload from '../../../MobilePhotoUpload/MobilePhotoUpload';

const Wrapper = styled.div`
    width: ${({ width }) => width};
    display: flex;
    justify-content: center;
   
    ${({ isMobile, isAnyMenuActive, isTextorPhotoActive }) =>
      isMobile
        ? `
    border: 1px solid #EBEBEB;
    width:100%;
    align-items: center;
    position: fixed;
    bottom:0;
    z-index: 9;
    left:0;
    background: white;
    text-align: center;
    .dotImage {
        // height: 20px;
        // top: -23px;
        position: relative;
        background: white;
        border-radius: 8px;
        padding: 0px 8px;
    }
    filter: drop-shadow(0px -5px 5px rgba(0,0,0,0.25));
    ${
      !isAnyMenuActive
        ? `
        padding: 9px;
        height: 20px;
        // filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        
        .mobileMenuBarButton{
                display:none;
            }
        `
        : `
        .dotImage {
               
                ${
                  isTextorPhotoActive
                    ? `
                     top: -13px;
                `
                    : `
                    top: -25px;
                `
                }

                left:50%
            }`
    }
    `
        : `
    position: relative;
    `}

    @media (max-width: ${props => props.theme.breakpoints.s}) {
        // width: 100%;
    }
`;
const ButtonWrapper = styled.div`
  ${({ isMobile }) =>
    isMobile
      ? `

    `
      : `
        margin-right: 30px;
    `}
  &:last-of-type {
    margin-right: 0px;
  }
  &.mobileMenuBarButton {
    flex: 1;
    text-align: center;
    padding-top: 10px;
    &.active {
      border-top: 3px solid #5095bf;
    }
  }
  a > svg {
    width: 40px;
    height: 20px;
    ${({ isMobile }) =>
      isMobile
        ? `

        `
        : `
             margin-bottom: 10px;
        `}
    filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .07));
    cursor: pointer;
  }
  > div {
    position: absolute;
    background: ${({ theme }) => theme.colors.white};
    box-shadow: ${({ theme }) => theme.shadow.menu};
    width: 90%;
    left: 5%;

    z-index: 1000;
    ${({ isMobile }) =>
      isMobile
        ? `
        width: 100%;
        left: 0;
        bottom: 42px;
        height: 286px;
        overflow: scroll;
        `
        : `
        top: 25px;
        `}}
  }
  .tooltip {
    position: relative;
    > .__react_component_tooltip {
      left: 0px !important;
      top: 0px !important;
      color: ${({ theme }) => theme.colors.black} !important;
    }
  }
`;
const MenuBarButton = ({
  title,
  menu,
  icon,
  handleClick,
  children,
  isMobile,
  isActive,
}) => {
  const { layout, activeMenu, setActiveMenu } = useContext(MenuBarContext);
  const Icon = icon;
  const classes = [];
  classes.push(layout.hasOwnProperty(menu) ? '' : 'hidden');
  classes.push(isMobile ? 'mobileMenuBarButton' : '');
  classes.push(isActive ? 'active' : '');
  const handleMenuClick = () => {
    setActiveMenu(activeMenu === menu ? modalConstants.HIDDEN : menu);
  };
  return (
    <ButtonWrapper isMobile={isMobile} className={classes.join(' ')}>
      {/* <ButtonWrapper className={layout.hasOwnProperty(menu) ? "" : ""}> */}
      {Icon && (
        <span className="button">
          <a data-tip data-for={'menubutton ' + menu}>
            <Icon onClick={handleMenuClick} />
          </a>
          {!isMobile && (
            <Tooltip
              id={'menubutton ' + menu}
              place="top"
              effect="solid"
              className="menubar-tooltip"
            >
              <span>{title}</span>
            </Tooltip>
          )}
        </span>
      )}
      {children}
    </ButtonWrapper>
  );
};

const MenuBar = ({
  layout,
  onClick,
  width,
  isMobile,
  canvasRef,
  toolbarProps,
  onToolbarClick,
}) => {
  const {
    library,
    setLibrary,
    pageContext: { card_type },
  } = useContext(MultiStepContext); // Context API for multi-step form status
  const isJustPhotoUploadAvailable =
    layout.hasOwnProperty('Photo upload') && Object.keys(layout)?.length === 1;
  const menuItems = queryHelper.parseLayout(layout); // divide the layout into separate objects
  const [activeMenu, setActiveMenu] = useState(modalConstants.HIDDEN); // state to show/hide the menu
  const newLayout = { ...layout };
  delete newLayout['Photo upload'];
  const noOptions = isEmpty(newLayout);
  const wrapperRef = useRef(null); // Ref to detect outside click
  useOnClickOutside(wrapperRef, () => setActiveMenu(modalConstants.HIDDEN));

  const setRef = useSwipeDetector({
    onUpSwipe: debounce(() => {
      const actveObject = canvasRef?.current?.canvas?.getActiveObject();
      if (modalConstants.HIDDEN === activeMenu) {
        if (noOptions) {
          setActiveMenu(modalConstants.OTHER);
        } else {
          if (!actveObject) {
            setActiveMenu(modalConstants.STICKER);
          }
        }
      }
    }, 100),
  });

  useEffect(() => {
    if (wrapperRef?.current) {
      setRef(wrapperRef?.current);
    }
  }, [wrapperRef?.current]);

  const handleClick = object => {
    setActiveMenu(modalConstants.HIDDEN); // Hide a menu when an user selects an object
    onClick(activeMenu, object);
  };
  const isPhotoUploadAvailable = [
    'Photo Canvas',
    'Templates',
    'Blank',
  ].includes(card_type);
  const isAnyMenuActive = [
    isPhotoUploadAvailable && modalConstants.OTHER,
    modalConstants.ART,
    modalConstants.EMOJI,
    modalConstants.STICKER,
    modalConstants.SCENE,
  ].includes(activeMenu);
  return (
    <>
      <Wrapper
        isMobile={isMobile && !isJustPhotoUploadAvailable}
        isTextorPhotoActive={activeMenu === modalConstants.OTHER}
        isAnyMenuActive={isAnyMenuActive}
        ref={wrapperRef}
        width={width}
      >
        <MenuBarContext.Provider
          value={{ layout, menuItems, activeMenu, setActiveMenu, handleClick }}
        >
          {isMobile && !isJustPhotoUploadAvailable && (
            <img
              onClick={() =>
                setActiveMenu(
                  activeMenu === modalConstants.HIDDEN
                    ? noOptions
                      ? modalConstants.OTHER
                      : modalConstants.STICKER
                    : modalConstants.HIDDEN
                )
              }
              src="/images/create-your-own/3-dot.png"
              alt=""
              className="dotImage"
            />
          )}
          <MenuBarButton
            isActive={activeMenu === modalConstants.STICKER}
            isMobile={isMobile}
            menu={modalConstants.STICKER}
            icon={StickyIcon}
            title="Stickers"
          >
            <Menu
              isMobile={isMobile}
              isActive={activeMenu === modalConstants.STICKER}
              photos={
                Array.isArray(menuItems.stickers) ? menuItems.stickers : []
              }
              //handleClick={ i => handleClick(menuItems.stickers[i].original) }
              // Replaced the handler to use the url string passed from Menu.jsx
              handleClick={i => handleClick(i)}
              columns={10}
              type="sticker"
              title="Stickers"
            />
          </MenuBarButton>

          <MenuBarButton
            isActive={activeMenu === modalConstants.EMOJI}
            isMobile={isMobile}
            menu={modalConstants.EMOJI}
            icon={EmojiIcon}
            title="Emojis"
          >
            <Menu
              isMobile={isMobile}
              isActive={activeMenu === modalConstants.EMOJI}
              photos={Array.isArray(menuItems.emojis) ? menuItems.emojis : []}
              //handleClick={ i => handleClick(menuItems.emojis[i].original) }
              // Replaced the handler to use the url string passed from Menu.jsx
              handleClick={i => handleClick(i)}
              columns={10}
              type="emoji"
              title="Emojis"
            />
          </MenuBarButton>
          <MenuBarButton
            isActive={activeMenu === modalConstants.ART}
            isMobile={isMobile}
            menu={modalConstants.ART}
            icon={ArtIcon}
            title="Arts"
          >
            <Menu
              isMobile={isMobile}
              isActive={activeMenu === modalConstants.ART}
              photos={Array.isArray(menuItems.arts) ? menuItems.arts : []}
              //handleClick={ i => handleClick(menuItems.arts[i].original) }
              // Replaced the handler to use the url string passed from Menu.jsx
              handleClick={i => handleClick(i)}
              columns={10}
              type="art"
              title="Arts"
            />
          </MenuBarButton>
          {isMobile && (
            <ToolBar
              {...toolbarProps}
              isAnyMenuActive={isAnyMenuActive}
              setActiveMenu={setActiveMenu}
            />
          )}
          <MenuBarButton
            isActive={activeMenu === modalConstants.SCENE}
            isMobile={isMobile}
            menu={modalConstants.SCENE}
            icon={BackgroundIcon}
            title="Background"
          >
            <Menu
              isMobile={isMobile}
              isActive={activeMenu === modalConstants.SCENE}
              photos={Array.isArray(menuItems.scenes) ? menuItems.scenes : []}
              //handleClick={ i => handleClick(menuItems.scenes[i].original) }
              // Replaced the handler to use the url string passed from Menu.jsx
              handleClick={i => handleClick(i)}
              type="scene"
              title="Background"
            />
          </MenuBarButton>
          {//isMobile && isPhotoUploadAvailable && ( -- removed so photo button shows correctly.
           isMobile && (
            <MobilePhotoUpload
              styles={{ flex: 1, textAlign: 'center', paddingTop: '10px' }}
              setActiveMenu={setActiveMenu}
              isAnyMenuActive={
                isAnyMenuActive || activeMenu === modalConstants.OTHER
              }
              onCrop={percentage => {
                onToolbarClick({
                  action: userActionConstants.CROP_IMAGE,
                  data: percentage, // angle
                });
              }}
              onRotate={angle => {
                onToolbarClick({
                  action: userActionConstants.ROTATE_CUSTOM,
                  data: angle, // angle
                });
              }}
              onZoom={zoomLevel => {
                onToolbarClick({
                  action: userActionConstants.ZOOM,
                  data: zoomLevel, // angle
                });
              }}
              canvasRef={canvasRef}
              onSelect={object => {
                onClick(modalConstants.LIBRARY, object);
              }}
            />
          )}
          {!isMobile && (
            <MenuBarButton
              isMobile={isMobile}
              menu={modalConstants.LIBRARY}
              icon={UploadIcon}
              title="Photo Upload"
            >
              <Library
                isActive={activeMenu === modalConstants.LIBRARY}
                photos={library}
                setPhotos={setLibrary}
                handleClick={obj => handleClick(obj)}
              />
            </MenuBarButton>
          )}
        </MenuBarContext.Provider>
      </Wrapper>
    </>
  );
};
MenuBar.propTypes = {
  width: PropTypes.string,
};
MenuBar.defaultProps = {
  width: '100%',
};
export default MenuBar;
