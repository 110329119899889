import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex !important;
  justify-content: center;
  position: relative;
  p,
  table,
  img {
    padding: 0px;
    margin: 0px;
  }
  ul {
    list-style: none;
    margin: 0px;
    p {
      font-size: 14px;
      line-height: 28px;
    }
    p.no-line-spacing {
      font-size: 14px;
      line-height: 24px;
    }
    li {
      margin: 0px;
    }
  }
  div.card {
    background: ${({ theme }) => theme.colors.white};
    box-shadow: ${({ theme }) => theme.shadow.card};
    border-radius: ${({ theme }) => theme.borderRadius.default};
    margin-left: 50px;
    padding: 10px;

    .email {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      input[type='text'] {
        font-size: 14px;
        width: 100%;
        border: none;
        border-bottom: 1px solid ${({ theme }) => theme.colors.stroke};
        &.required {
          border-bottom: 1px solid ${({ theme }) => theme.colors.error};
        }
        outline: none;
        margin-left: 10px;
      }
    }

    @media (max-width: ${props => props.theme.breakpoints.s}) {
      margin-left: 0px;
      padding: 0px;
      box-shadow: none;
    }
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid ${({ theme }) => theme.colors.stroke};
    margin-top: 10px;
    &:first-of-type {
      margin-top: 0px;
    }
  }
  .control {
    font-weight: normal;
    font-size: 12px;
    display: flex;
    > span {
      color: ${({ theme }) => theme.colors.grey};
      font-weight: normal;
      margin-right: 15px;
      cursor: pointer;
      > svg {
        margin-right: 2px;
      }
      &:last-of-type {
        margin-right: 0px;
      }
      &:hover {
        color: ${({ theme }) => theme.colors.blue};
      }
    }
  }
  .options {
    height: fit-content;
    ul {
      &:last-of-type {
        margin-bottom: 0px;
        border-bottom: none;
      }
      > li {
        display: flex;
        align-items: center;
        margin: 0px;
        &:last-of-type {
          margin-bottom: 0px;
        }
        > img {
          margin: 0px;
        }
      }
    }
  }
  .thumbnail {
    margin-bottom: 15px;
  }
  .order-summary {
    .summary tr > td {
      font-size: 12px;
      border: none;
      padding: 0px;
      margin: 0px;
      line-height: 32px;
      &:nth-of-type(3) {
        display: flex;
        justify-content: space-evenly;
      }
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    flex-direction: column;
    div.options {
      margin-bottom: 20px;
    }
  }
`;
export const Title = styled.h3`
  font-weight: 700;
  font-size: 14px;
  line-height: 30.48px;
  margin: 0px;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 14px;
    line-height: 28px;
  }
`;

export const PreviewWrapper = styled.div`
  position: relative;
  width: ${({ width, height, bLandscape }) =>
    `${bLandscape ? height : width}px`};
  height: ${({ width, height, bLandscape }) =>
    `${bLandscape ? width : height}px`};

  inset: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 6;

  .preview {
    width: ${({ width, height, bLandscape }) =>
      `${bLandscape ? height : width}px`};
    height: ${({ width, height, bLandscape }) =>
      `${bLandscape ? width : height}px`};
    transform-style: preserve-3d;
    transition: all 1s ease;

    top: 0;
    left: 0;
    transform: translate(calc(50vw - 50%), calc(50vh - 50%));
    ${({ activePreview, bLandscape }) => {
    
      if(activePreview==='inside-left'){
        const translateString = `translate(calc(${bLandscape?'68vw':'50vw'}), calc(50vh - 50%))`;
        return `
          transform: ${translateString};
        `;
      }
    }};
    z-index: 111;
  }
  .preview-image {
    max-width: ${({ width, height, bLandscape }) => `${width}px`};
    height: ${({ width, height, bLandscape }) => `${height}px`};
  }

  .preview-cont {
    transform: ${({ bLandscape }) => (bLandscape ? 'rotate(90deg)' : '')};
  }
`;
