import React, { useContext } from 'react';
import styled from '@emotion/styled';
// import core components
import { LinkButton } from 'components';
import { MultiStepContext } from "contexts";
import { multiStepConstants } from '../../../constants';
const slugHelper = require('helpers/gatsbyHelper');

const Wrapper = styled.div`
  display: flex;
`;

const Header = ({title, category}) => {
  const { currentStep } = useContext(MultiStepContext); // Context API for multi-step form status
  return (
    <Wrapper>
        <LinkButton
          text="Back to Category"
          link={slugHelper.generateCategorySlug(category)}
          visible={currentStep === multiStepConstants.STEP_FRONT}
        />
    </Wrapper>
  );
};
export default Header;